import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import SpinnerCardHeader from 'components/common/SpinnerCardHeader';
import { useTranslation } from 'react-i18next';
import UserService from 'http/UserService';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import { validatePhoneNumber } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const ProfileSettings = ({ user }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    setFormData(user);
  }, [user]);

  const handleChange = e => {
    if (e.target.name === 'phoneNumber') {
      if (!!e.target.value && isNaN(e.target.value)) {
        return;
      }
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = () => {
    const isValid = handleValidate(formData);
    if (isValid) {
      setLoading(true);
      UserService.update({
        id: user.id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        subtitle: formData.subtitle,
        bio: formData.bio
      })
        .then(response => {
          if (response) {
            toast.success(t('user:message.updateUserSettingsSuccess'), {
              theme: 'colored'
            });
            //setUser({ ...formData });
          } else {
            toast.error(getErrorMessage(t, response), {
              theme: 'colored'
            });
          }
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          toast.error(getErrorMessage(t, error), {
            theme: 'colored'
          });
        });
    } else {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored'
      });
    }
  };

  const handleValidate = data => {
    let errorsObj = {};
    if (!data.firstName) {
      errorsObj['firstName'] = {
        invalid: true,
        message: t('common:validation.fieldIsRequired')
      };
    } else {
      errorsObj['firstName'] = {
        invalid: false
      };
    }
    if (!data.lastName) {
      errorsObj['lastName'] = {
        invalid: true,
        message: t('common:validation.fieldIsRequired')
      };
    } else {
      errorsObj['lastName'] = {
        invalid: false
      };
    }
    if (!!data.phoneNumber) {
      if (!validatePhoneNumber(`+${data.phoneNumber}`)) {
        errorsObj['phoneNumber'] = {
          invalid: true,
          message: t('user:validation.invalidPhoneNumber')
        };
      } else {
        errorsObj['phoneNumber'] = {
          invalid: false
        };
      }
    }
    setErrors(errorsObj);
    return Object.values(errorsObj).every(val => !val.invalid);
  };

  if (!user) {
    return '';
  }

  return (
    <Card>
      <SpinnerCardHeader title={t('user:labels.settingsTitle')} />
      <Card.Body className="bg-light">
        <Form>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="firstName">
              <Form.Label>
                {t('user:labels.firstName')}{' '}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t('user:labels.firstName')}
                value={formData?.firstName || ''}
                name="firstName"
                onChange={handleChange}
                isInvalid={errors?.firstName?.invalid}
              />
              {errors?.firstName?.message && (
                <Form.Control.Feedback type="invalid">
                  {errors?.firstName.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="lastName">
              <Form.Label>
                {t('user:labels.lastName')}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t('user:labels.lastName')}
                value={formData?.lastName || ''}
                name="lastName"
                onChange={handleChange}
                isInvalid={errors?.lastName?.invalid}
              />
              {errors?.lastName?.message && (
                <Form.Text className="text-danger">
                  {errors?.lastName.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="email">
              <Form.Label>{t('user:labels.email')}</Form.Label>
              <Form.Control
                type="email"
                placeholder={t('user:labels.email')}
                value={formData?.email || formData?.emailAddress || ''}
                name="email"
                onChange={() => {}}
                isInvalid={errors?.email?.invalid}
                disabled
              />
              {errors?.email?.message && (
                <Form.Text className="text-danger">
                  {errors?.email.message}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="phoneNumber">
              <Form.Label>{t('user:labels.phoneNumber')}</Form.Label>
              <InputGroup className="mb-2">
                <Form.Control
                  type="text"
                  placeholder={t('user:labels.phoneNumber')}
                  value={formData?.phoneNumber || ''}
                  name="phoneNumber"
                  onChange={handleChange}
                  isInvalid={errors?.phoneNumber?.invalid}
                />
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="user-phoneNumber-tooltip">
                      {t('user:tooltips.phoneNumber')}
                    </Tooltip>
                  }
                >
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faExclamationCircle} />
                  </InputGroup.Text>
                </OverlayTrigger>
              </InputGroup>
              {errors?.phoneNumber?.message && (
                <Form.Text className="text-danger">
                  {errors?.phoneNumber.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" controlId="subtitle">
            <Form.Label>{t('user:labels.heading')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('user:labels.heading')}
              value={formData?.subtitle || ''}
              name="subtitle"
              onChange={handleChange}
              isInvalid={errors?.subtitle?.invalid}
            />
            {errors?.subtitle?.message && (
              <Form.Text className="text-danger">
                {errors?.subtitle.message}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className="mb-3" controlId="bio">
            <Form.Label>{t('user:labels.intro')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={13}
              placeholder={t('user:labels.intro')}
              value={formData?.bio || ''}
              name="bio"
              onChange={handleChange}
              isInvalid={errors?.bio?.invalid}
            />
            {errors?.bio?.message && (
              <Form.Text className="text-danger">
                {errors?.bio.message}
              </Form.Text>
            )}
          </Form.Group>
          <div className="text-end">
            <Button
              variant="primary"
              type="button"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading && <Spinner size="sm" />}
              {!loading && t('common:button.update')}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ProfileSettings;
