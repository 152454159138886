import { faFileSignature, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmModal from 'components/common/ConfirmModal';
import { CampaignContext } from 'context/Context';
import { getActionButtonColor } from 'helpers/utils';
import CampaignService from 'http/CampaignService';
import { getErrorMessage } from 'http/utils';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { Button, Card, ListGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const CampaignActionCard = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const {
    campaign,
    validateCampaignForSave,
    validateCampaignForSubmit,
    campaignPackage,
    packageLoading,
    setLoading,
    loading,
    isEdit,
    setIsEdit,
    fetchCampaign
  } = useContext(CampaignContext);
  const [availableTransitions, setAvailableTransitions] = useState([]);
  const [confirmModalProps, setConfirmModalProps] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  const {
    isLoading: campaignTransitionsLoading,
    error: campaignTransitionsError,
    data: campaignTransitionsData,
    refetch: fetchCampaignTransitions
  } = useQuery(
    ['ListCampaignAvailableTransitions', { id }],
    () => CampaignService.listAvailableTransitions(id),
    {
      enabled: false, // Prevents automatic refetching
      retry: false,
      manual: true,
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    if (!!id) {
      fetchCampaignTransitions();
    }
  }, []);

  useEffect(() => {
    if (
      campaignTransitionsData &&
      !campaignTransitionsLoading &&
      !campaignTransitionsError
    ) {
      setAvailableTransitions(campaignTransitionsData);
    }
    if (campaignTransitionsError) {
      toast.error(getErrorMessage(t, campaignTransitionsError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [
    campaignTransitionsData,
    campaignTransitionsError,
    campaignTransitionsLoading
  ]);

  const handleSaveCampaign = () => {
    if (
      !!campaign?.state &&
      campaign?.state?.toLowerCase() !== 'draft' &&
      campaign?.state?.toLowerCase() !== 'modificationsrequested'
    ) {
      return;
    }
    const inError = validateCampaignForSave(campaign);
    if (inError) {
      return;
    }
    setLoading(true);
    //console.log('submit data', { ...campaign });
    CampaignService.saveCampaign({
      ...campaign,
      startDate: new Date(campaign?.startDate).toISOString(),
      endDate: new Date(campaign?.endDate).toISOString(),
      organizations: campaign?.organizations,
      products: campaign?.products
    })
      .then(response => {
        toast.success(t('campaign:message.saveSuccess'));
        setIsEdit(false);
        setLoading(false);
        if (!campaign.id) {
          navigate(`/campaign/${response.data.id}/${response.data.packageId}`);
        }
      })
      .catch(error => {
        toast.error(getErrorMessage(t, error));
        setLoading(false);
      });
  };

  const handleClickDoTransition = action => {
    //console.log('handleClickDoTransition', { action });
    const isDelete = action?.toLowerCase() === 'delete';
    if (!isDelete) {
      const inError = validateCampaignForSubmit(campaign);
      if (inError) {
        toast.error(
          t('campaign:message.fillRequiredFields', {
            action: t(`campaign:action.${action}`)
          })
        );
        return;
      }
    }

    setConfirmModalProps({
      isOpen: true,
      onClose: () => setConfirmModalProps(null),
      onConfirm: () => handleDoTransition(id, action),
      message: t('campaign:message.actionConfirm', {
        action: t(`campaign:action.${action}`)
      }),
      header: t(`campaign:action.${action}`),
      actionButtonColor: getActionButtonColor(action),
      actionButtonText: t(`campaign:action.${action}`)
    });
  };

  const handleDoTransition = (id, action) => {
    const isDelete = action?.toLowerCase() === 'delete';
    setActionLoading(true);
    CampaignService.doAction(id, action)
      .then(response => {
        if (isDelete) {
          navigate('/campaigns');
        } else {
          fetchCampaign && fetchCampaign();
          fetchCampaignTransitions();
          setConfirmModalProps(null);
          toast.success(t(`campaign:message.${action}Success`));
          setActionLoading(false);
        }
      })
      .catch(error => {
        setActionLoading(false);
        toast.error(getErrorMessage(t, error));
      });
  };

  const allowEdit = state => {
    return (
      campaign?.state?.toLowerCase() === 'draft' ||
      campaign?.state?.toLowerCase() === 'modificationsrequested'
    );
  };

  if (packageLoading) {
    return '';
  }

  return (
    <Card>
      <Card.Header>{t('organization:labels.actionCardTitle')}</Card.Header>
      <Card.Body>
        <Row>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <FontAwesomeIcon icon={faFileSignature} />
              <span className="mx-2">
                {campaignPackage?.title?.[currentLanguage]}
              </span>
            </ListGroup.Item>
            <ListGroup.Item>
              <FontAwesomeIcon icon={faUsers} />
              <span className="mx-2">
                {`${campaignPackage?.tokensCount} ${t(
                  'campaign:labels.tokens'
                )}`}
              </span>
            </ListGroup.Item>
          </ListGroup>
        </Row>
        <Row className="mt-3 px-2">
          {(!campaign?.state || allowEdit(campaign?.state?.toLowerCase())) &&
            isEdit && (
              <>
                <Button onClick={handleSaveCampaign} disabled={loading}>
                  {t('organization:button.saveAsDraft')}
                </Button>
                {!!campaign?.id && (
                  <Button
                    className="mt-2"
                    onClick={() => setIsEdit(false)}
                    disabled={loading}
                    variant="danger"
                  >
                    {t('common:button.cancel')}
                  </Button>
                )}
              </>
            )}
          {(!campaign?.state || allowEdit(campaign?.state?.toLowerCase())) &&
            !isEdit && (
              <Button onClick={() => setIsEdit(true)} variant="primary">
                {t('common:button.edit')}
              </Button>
            )}
          {!isEdit &&
            availableTransitions?.map((transition, index) => {
              return (
                <Button
                  key={transition?.action || index}
                  onClick={() => handleClickDoTransition(transition?.action)}
                  disabled={loading}
                  className="mt-2"
                  variant={getActionButtonColor(transition?.action)}
                >
                  {t(`campaign:action.${transition.action}`)}
                </Button>
              );
            })}
        </Row>
      </Card.Body>
      <ConfirmModal {...confirmModalProps} loading={actionLoading} />
    </Card>
  );
};

export default CampaignActionCard;
