import React, { useState, useEffect, useContext } from 'react';
import Avatar from 'components/common/Avatar';
import ErrorPlaceholder from 'components/common/ErrorPlaceholder';
import LoaderWithMessage from 'components/common/LoaderWithMessage';
import OrganizationService from 'http/OrganizationService';
import { toFileUrl } from 'http/utils';
import { Card, Col, Form, ListGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileSignature,
  faMapMarker
} from '@fortawesome/free-solid-svg-icons';
import { getSocialIcon } from 'helpers/utils';
import SelectComponent from 'components/common/SelectComponent';
import { CampaignContext } from 'context/Context';
import SoftBadge from 'components/common/SoftBadge';

const CompanyInfoCard = ({ organizationId }) => {
  const { campaign, setCampaign, campaignErrors, isEdit } =
    useContext(CampaignContext);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [organization, setOrganization] = useState(null);
  const [organizationsOptions, setOrganizationsOptions] = useState(null);

  const {
    isLoading: organizationLoading,
    error: organizationError,
    data: organizationData,
    refetch: fetchOrganization
  } = useQuery(
    ['GetOrganization', organizationId],
    () => OrganizationService.get(organizationId),
    {
      enabled: false,
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    if (organizationId) {
      fetchOrganization(organizationId);
    }
  }, [organizationId]);

  useEffect(() => {
    if (organizationData && !organizationError && !organizationLoading) {
      let allOrgs = [
        { ...organizationData },
        ...(organizationData?.branches || [])
      ];
      setOrganizationsOptions(
        allOrgs.map(org => {
          return {
            ...org,
            label: `${org.name} (${
              !!org.parentId
                ? t(`organization:type.branch`)
                : t(`organization:type.headQuarter`)
            })`,
            value: org.id
          };
        })
      );
      setOrganization(organizationData);
    }
  }, [organizationData, organizationError, organizationLoading]);

  if (organizationLoading) {
    return <LoaderWithMessage message={t('organization:loading.loadingOrg')} />;
  }

  if (organizationError) {
    return (
      <ErrorPlaceholder
        message={t('organization:loading.errorOrg')}
        className="mt-3"
      />
    );
  }

  if (!organizationId || !organization) {
    return '';
  }

  return (
    <Card className="mt-3">
      <Card.Header>{t('organization:labels.effectiveBranches')}</Card.Header>
      <Card.Body>
        <Row>
          <Form.Group
            as={Col}
            xs="12"
            className="mb-3"
            controlId="userForm.organization"
          >
            <Form.Label>{t('campaign:labels.branch')}</Form.Label>
            <SelectComponent
              options={organizationsOptions}
              placeholder={t('user:labels.organization')}
              classNamePrefix="react-select"
              value={
                organizationsOptions?.filter(
                  o => !!campaign?.organizations?.find(oo => oo === o.id)
                ) || []
              }
              isMulti
              onChange={value =>
                setCampaign({
                  ...campaign,
                  organizations: value.map(o => o.id)
                })
              }
              errorText={campaignErrors?.organizations || ''}
              invalid={!!campaignErrors?.organizations}
              isDisabled={!isEdit}
            />
          </Form.Group>
        </Row>

        {organizationsOptions?.map((org, index) => {
          if (!campaign?.organizations?.includes(org.id)) {
            return '';
          }
          return (
            <Row key={`campaign-selected-branches-${index}`}>
              <Col md={2}>
                <Avatar size="4xl" src={toFileUrl(org?.avatarUrl)} />
              </Col>
              <Col md={10}>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <FontAwesomeIcon icon={faFileSignature} />
                    <span className="mx-2">{org.name}</span>
                    <SoftBadge
                      bg={!org.parentId ? 'primary' : 'warning'}
                      className="mx-2 mt-2"
                    >
                      {!!org.parentId
                        ? t(`organization:type.branch`)
                        : t(`organization:type.headQuarter`)}
                    </SoftBadge>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <FontAwesomeIcon icon={faMapMarker} />
                    <span className="mx-2">
                      {`${org?.country?.name[currentLanguage]}, ${org.city}`}
                    </span>
                  </ListGroup.Item>

                  {org?.socialLinks?.map(socialLink => {
                    return (
                      <ListGroup.Item key={socialLink.link}>
                        <FontAwesomeIcon
                          icon={getSocialIcon(socialLink.socialPlatform)}
                        />
                        <a href={socialLink.link} className="mx-2">
                          {org.name}
                        </a>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </Col>
            </Row>
          );
        })}
      </Card.Body>
    </Card>
  );
};

export default CompanyInfoCard;
