import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import i18n from './i18n';
import AuthProvider from 'providers/AuthProvider';
const queryClient = new QueryClient();

const container = document.getElementById('main');
const root = createRoot(container);
//console.log('Mount index:::');
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <Main>
            <App />
          </Main>
        </QueryClientProvider>
      </AuthProvider>
    </I18nextProvider>
  </React.StrictMode>
);
