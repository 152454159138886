import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import SpinnerCloseButton from 'components/common/SpinnerCloseButton';
import { useTranslation } from 'react-i18next';
import SelectComponent from 'components/common/SelectComponent';
import { toast } from 'react-toastify';
import { getErrorMessage, toFileUrl } from 'http/utils';
import {
  getSocialIcon,
  validateEmail,
  validatePhoneNumber
} from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faGlobe,
  faPlus,
  faToggleOff,
  faToggleOn,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import Avatar from 'components/common/Avatar';
import defaultAvatar from 'assets/img/team/avatar.png';
import { useContext } from 'react';
import { DataContext } from 'context/Context';
import OrganizationService from 'http/OrganizationService';
import {
  faFacebook,
  faInstagram,
  faSnapchat,
  faTiktok,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useBreakpoints } from 'hooks/useBreakpoints';
import BranchModal from './BranchModal';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import moment from 'moment';
import SoftBadge from 'components/common/SoftBadge';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import ConfirmModal from 'components/common/ConfirmModal';
import { useQuery } from 'react-query';

const initFormData = {
  name: null,
  description: null,
  address: null,
  city: null,
  contactEmail: null,
  contactPhone: null,
  status: null,
  country: null,
  avatarUrl: null,
  additionalContactPhone: null,
  avatar: null,
  parentId: null,
  socialLinks: [],
  location: null
};

const requiredFields = [
  'name',
  'country',
  'city',
  'contactPhone',
  'contactEmail',
  'location'
];

const socialPlatforms = [
  'facebook',
  'youtube',
  'instagram',
  'snapchat',
  'tiktok',
  'x',
  'web'
];

const defaultSorting = {
  sortBy: 'metadata.createdAt',
  sortDirection: 'DESC'
};

const OrganizationSettings = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { breakpoints } = useBreakpoints();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [organization, setOrganization] = useState(null);
  const { countries } = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [formData, setFormData] = useState(null);
  const [editDisabled, setEditDisabled] = useState(true);
  const [branchModalOpen, setBranchModalOpen] = useState(false);
  const [branchToEdit, setBranchToEdit] = useState(null);
  const [errors, setErrors] = useState(null);
  const [sortInfo, setSortInfo] = useState({ ...defaultSorting });
  const [confirmModalProps, setConfirmModalProps] = useState(null);

  if (!id) {
    navigate(-1);
  }

  const organizationId = id;

  const {
    isLoading: organizationLoading,
    error: organizationError,
    data: organizationData,
    refetch: fetchOrganization
  } = useQuery(
    ['GetOrganization', organizationId],
    () => OrganizationService.get(organizationId),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    if (organizationId) {
      fetchOrganization(organizationId);
    } else {
      navigate(-1);
    }
  }, [fetchOrganization, organizationId]);

  useEffect(() => {
    if (loading) {
      setEditDisabled(loading);
    }
  }, [loading]);

  useEffect(() => {
    if (!!organizationData && !organizationError && !organizationLoading) {
      setFormData({ ...organizationData });
      setOrganization(organizationData);
    }
    if (organizationError) {
      navigate(-1);
    }
  }, [organizationLoading, organizationError, organizationData]);

  const handleChange = (name, value) => {
    if (name === 'socialLinks') {
      setFormData({
        ...formData,
        socialLinks: value.map(s => ({ link: '', socialPlatform: s.value }))
      });

      return;
    }

    if (name === 'location') {
      setFormData({
        ...formData,
        location: {
          link: value
        }
      });

      return;
    }

    if (name === 'socialLinkValue') {
      let newData = [...formData?.socialLinks];
      for (let index = 0; index < newData.length; index++) {
        if (newData[index]?.socialPlatform === value.platform) {
          newData[index].link = value.link;
        }
      }
      setFormData({
        ...formData,
        socialLinks: newData
      });

      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const formDataJson = JSON.stringify({ ...formData });
    const userDataJson = JSON.stringify({ ...organization });
    if (formDataJson !== userDataJson) {
      setHasChange(true);
    } else {
      setHasChange(false);
    }
  }, [formData]);

  const handleSubmit = () => {
    console.log('handleSubmit', { formData });

    const hasError = handleValidateFormData(formData ?? {});

    if (hasError) {
      return;
    }

    setLoading(true);

    let payload = {
      ...formData,
      id: organization?.id ?? null,
      countryCode: formData.country?.code,
      status: !!organization?.id ? organization?.status : 'active'
    };

    delete payload['country'];
    delete payload['avatarPreview'];

    OrganizationService.create({ ...payload })
      .then(response => {
        setLoading(false);
        setEditDisabled(true);
        toast.success(
          t(
            `organization:message.${
              !!organization?.id ? 'updateSuccess' : 'createSuccess'
            }`
          )
        );
      })
      .catch(error => {
        setLoading(false);
        toast.error(getErrorMessage(error));
      });
  };

  const handleUploadImage = event => {
    const file = event?.target?.files?.[0];
    if (!file) {
      return;
    }
    const objectUrl = URL.createObjectURL(file);
    setFormData({ ...formData, avatar: file, avatarPreview: objectUrl });
  };

  const handleValidateFormData = data => {
    if (!data) {
      return true;
    }
    let errorsObj = null;
    Object.entries(data).map(([key, value]) => {
      if (key === 'metadata') {
        return;
      }
      if (key === 'contactEmail' && !!value) {
        if (!validateEmail(value)) {
          errorsObj = {
            ...errorsObj,
            [key]: {
              invalid: true,
              message: t('common:validation.invalidEmail')
            }
          };
          return;
        }
      }
      if (key === 'location') {
        if (!value?.link) {
          errorsObj = {
            ...errorsObj,
            [key]: {
              invalid: true,
              message: t('common:validation.fieldIsRequired')
            }
          };
          return;
        } else {
          errorsObj = {
            ...errorsObj,
            [key]: null
          };
          return;
        }
      }
      if (
        (key === 'contactPhone' || key === 'additionalContactPhone') &&
        !!value
      ) {
        if (!validatePhoneNumber(`+${value}`)) {
          errorsObj = {
            ...errorsObj,
            [key]: {
              invalid: true,
              message: t('common:validation.invalidPhoneNumber')
            }
          };
          return;
        }
      }
      if (key === 'socialLinks') {
        value?.map(socialLink => {
          if (!socialLink.link) {
            errorsObj = {
              ...errorsObj,
              [socialLink.socialPlatform]: {
                invalid: true,
                message: t('common:validation.fieldIsRequired')
              }
            };
          } else {
            errorsObj = {
              ...errorsObj,
              [socialLink.socialPlatform]: null
            };
          }
        });
      }
      if (!value && requiredFields.includes(key)) {
        errorsObj = {
          ...errorsObj,
          [key]: {
            invalid: true,
            message: t('common:validation.fieldIsRequired')
          }
        };
        return;
      } else {
        errorsObj = { ...errorsObj, [key]: null };
        return;
      }
    });
    setErrors(errorsObj);
    let inError = false;
    if (Object.values(errorsObj ?? {}).some(val => !!val)) {
      inError = true;
    }
    console.log('errorsObj', errorsObj);
    return inError;
  };

  const handleCancel = () => {
    setFormData({ ...organization });
    setEditDisabled(true);
    setErrors(null);
  };

  const handleSort = column => {
    //console.log('handleSort', column);
    const direction = sortInfo.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    setSortInfo({
      sortBy: column,
      sortDirection: direction
    });
  };

  const handleDeleteClick = id => {
    setConfirmModalProps({
      isOpen: true,
      onClose: () => setConfirmModalProps(null),
      onConfirm: () => handleDelete(id),
      message: t('organization:message.deleteConfirm'),
      header: t('organization:message.deleteConfirmHeader'),
      actionButtonColor: 'danger',
      actionButtonText: t('common:button.delete')
    });
  };

  const handleActivateClick = id => {
    setConfirmModalProps({
      isOpen: true,
      onClose: () => setConfirmModalProps(null),
      onConfirm: () => handleUpdateStatus({ id, status: 'active' }),
      message: t('organization:message.activateConfirm'),
      header: t('organization:message.activateConfirmHeader'),
      actionButtonColor: 'success',
      actionButtonText: t('common:button.activate')
    });
  };

  const handleSuspendClick = id => {
    setConfirmModalProps({
      isOpen: true,
      onClose: () => setConfirmModalProps(null),
      onConfirm: () => handleUpdateStatus({ id, status: 'suspended' }),
      message: t('organization:message.suspendConfirm'),
      header: t('organization:message.suspendConfirmHeader'),
      actionButtonColor: 'warning',
      actionButtonText: t('common:button.suspend')
    });
  };

  const handleDelete = id => {
    setConfirmModalProps({
      ...confirmModalProps,
      loading: true
    });

    OrganizationService.delete(id)
      .then(response => {
        fetchOrganization(organizationId);
        toast.success(t('organization:message.deleteSuccess'));
      })
      .catch(error => {
        toast.error(getErrorMessage(error));
      });
  };

  const handleUpdateStatus = data => {
    setConfirmModalProps({
      ...confirmModalProps,
      loading: true
    });

    OrganizationService.updateStatus({ ...data })
      .then(response => {
        fetchOrganization(organizationId);
        toast.success(t('organization:message.updateSuccess'));
      })
      .catch(error => {
        toast.error(getErrorMessage(error));
      });
  };

  const columns = [
    {
      accessor: 'name',
      Header: t('organization:table.name'),
      headerProps: {
        className: 'px-3 text-center',
        style: { height: '46px' },
        onClick: () => handleSort('NormalizedDisplayName')
      },
      cellProps: {
        className: 'py-2 white-space-nowrap px-3 pe-xxl-4 '
      },
      Cell: rowData => {
        const { name, avatarUrl, id } = rowData.row.original;
        return (
          <Flex alignItems="center" className="position-relative py-1 ">
            <Avatar
              src={toFileUrl(avatarUrl)}
              size="xl"
              className="me-2"
              name={name || t('common:labels.unknown')}
            />
            <Link
              to={`/user/profile/${id}`}
              className="stretched-link fw-semi-bold"
            >
              {name}
            </Link>
          </Flex>
        );
      }
    },
    {
      accessor: 'type',
      Header: t('organization:table.type'),
      headerProps: {
        className: 'px-3 text-center',
        style: { height: '46px' }
      },
      cellProps: {
        className: 'py-2 white-space-nowrap px-3 pe-xxl-4 '
      },
      Cell: rowData => {
        const { parentId } = rowData.row.original;
        return (
          <SoftBadge
            bg={!parentId ? 'primary' : 'warning'}
            className="me-2 mt-2"
          >
            {!!parentId
              ? t(`organization:type.branch`)
              : t(`organization:type.headQuarter`)}
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'country',
      Header: t('organization:table.country'),
      headerProps: {
        onClick: () => handleSort('NormalizedEmail')
      },
      cellProps: {
        className: 'py-2 pe-4'
      },
      Cell: rowData => {
        const { country } = rowData.row.original;
        return (
          <p className="fw-semi-bold break-word m-0 mt-2">{`${country.name?.[currentLanguage]}`}</p>
        );
      }
    },
    {
      accessor: 'city',
      Header: t('organization:table.city'),
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'pe-4'
      },
      disableSortBy: true,
      Cell: rowData => {
        const { city } = rowData.row.original;
        return <p className="fw-semi-bold break-word m-0 mt-2">{`${city}`}</p>;
      }
    },
    {
      accessor: 'contact',
      Header: t('organization:table.contact'),
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'pe-4'
      },
      disableSortBy: true,
      Cell: rowData => {
        const { contactEmail, contactPhone, additionalContactPhone } =
          rowData.row.original;
        return (
          <div className="d-flex align-items-start justify-content-center flex-column">
            <p className="fw-semi-bold break-word m-0 mt-2">
              {`${t('organization:table.contactEmail')} : `}
              <a href="mailto:contactEmail">{contactEmail || ''}</a>
            </p>
            <p className="fw-semi-bold break-word m-0 mt-2">{`${t(
              'organization:table.contactPhone'
            )} : ${contactPhone || ''}`}</p>
            <p className="fw-semi-bold break-word m-0 mt-2">{`${t(
              'organization:table.additionalContactPhone'
            )} : ${additionalContactPhone || ''}`}</p>
          </div>
        );
      }
    },
    {
      accessor: 'status',
      Header: t('organization:table.status'),
      headerProps: {
        onClick: () => handleSort('Status')
      },
      cellProps: {
        className: 'fs-0 '
      },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return (
          <SoftBadge
            bg={
              status === 'active'
                ? 'success'
                : status === 'suspended'
                ? 'warning'
                : 'dark'
            }
            className="me-2 mt-2"
          >
            {status ? t(`common:status.${status}`) : t(`common:labels.unknown`)}
          </SoftBadge>
        );
      }
    },

    {
      accessor: 'metadata.createdAt',
      Header: t('organization:table.createdAt'),
      headerProps: {
        className: 'text-start',
        onClick: () => handleSort('metadata.createdAt')
      },
      Cell: rowData => {
        const { metadata } = rowData.row.original;
        return (
          <p className="fw-semi-bold mb-0 mt-2">
            {metadata?.createdAt
              ? moment(metadata?.createdAt)
                  .locale(currentLanguage)
                  .format('YYYY-MM-DD hh:mm A')
              : t('common:labels.noDataAvailable')}
          </p>
        );
      }
    },
    {
      accessor: 'action',
      Header: t('organization:table.action'),
      headerProps: { className: 'text-start' },
      disableSortBy: true,
      exportable: false,
      Cell: rowData => {
        const { status, id } = rowData.row.original;
        const isActive = status === 'active';

        return (
          <Dropdown
            align="end"
            className="btn-reveal-trigger d-inline-block mt-2"
          >
            <Dropdown.Toggle split variant="spinner-default" size="sm">
              <FontAwesomeIcon icon="ellipsis-h" className="fs--2" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="border py-0">
              <div className="py-2">
                <Dropdown.Item onClick={() => handleDeleteClick(id)}>
                  <FontAwesomeIcon icon={faTrash} className="mx-1" />
                  {t('common:button.delete')}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setBranchToEdit(rowData.row.original)}
                >
                  <FontAwesomeIcon icon={faEdit} className="mx-1" />
                  {t('common:button.edit')}
                </Dropdown.Item>

                {isActive && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      className="text-danger"
                      onClick={() => handleSuspendClick(id)}
                    >
                      <FontAwesomeIcon icon={faToggleOff} className="mx-1" />
                      {t('common:button.suspend')}
                    </Dropdown.Item>
                  </>
                )}
                {!isActive && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      className="text-success"
                      onClick={() => handleActivateClick(id)}
                    >
                      <FontAwesomeIcon icon={faToggleOn} className="mx-1" />
                      {t('common:button.activate')}
                    </Dropdown.Item>
                  </>
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        );
      }
    }
  ];

  return (
    <div>
      <Row className="gx-3">
        <Col md={9}>
          <Card>
            <Card.Header className="border-bottom border-200">
              {t('user:labels.organizationSettings')}
            </Card.Header>
            <Card.Body>
              <Row className="w-100 gx-0 gy-1">
                <Col md={12} className="mt-1 g-1 text-center">
                  <Avatar
                    ref={undefined}
                    size="4xl"
                    src={
                      formData?.avatarPreview
                        ? formData?.avatarPreview
                        : formData?.avatarUrl
                        ? toFileUrl(formData?.avatarUrl)
                        : defaultAvatar
                    }
                    loading={loading}
                    enableUpload
                    onUpload={file => handleUploadImage(file)}
                    overlayClassName="no-border"
                    tooltip={t('organization:form.logo')}
                    tooltipPlacement="bottom"
                    mediaClass={`${
                      errors?.avatarUrl?.error ? 'border-danger' : ''
                    }`}
                  />
                </Col>
                <Form.Text className="text-danger w-100 text-center">
                  {!!errors?.avatarUrl?.error &&
                    t('common:validation.imageIsRequired')}
                </Form.Text>
              </Row>
              <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
                <Col
                  xs="auto"
                  className="navbar-vertical-label navbar-vertical-label"
                >
                  {t('organization:form.basicInfo')}
                </Col>
                <Col className="ps-0">
                  <hr className="mb-0 navbar-vertical-divider"></hr>
                </Col>
              </Row>
              <Row>
                <Form.Group
                  as={Col}
                  xs="12"
                  //lg="4"
                  className="mb-3"
                  controlId="organizationForm.name"
                >
                  <Form.Label>
                    {t('organization:form.name')}{' '}
                    {requiredFields.includes('name') && (
                      <span className="text-danger">*</span>
                    )}
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      type="text"
                      placeholder={t('organization:form.name')}
                      onChange={e => handleChange('name', e.target.value)}
                      isInvalid={errors?.name?.invalid}
                      value={formData?.name || formData?.name || ''}
                      disabled={editDisabled}
                    />

                    {errors?.name?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors?.name?.message}
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs="12"
                  className="mb-3"
                  controlId="organizationForm.description"
                >
                  <Form.Label>
                    {t('organization:form.description')}
                    {requiredFields.includes('description') && (
                      <span className="text-danger">*</span>
                    )}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    placeholder={t('organization:form.description')}
                    onChange={e => handleChange('description', e.target.value)}
                    isInvalid={errors?.description?.invalid}
                    value={formData?.description || ''}
                    disabled={editDisabled}
                  />
                  {errors?.description?.message && (
                    <Form.Control.Feedback type="invalid">
                      {errors?.description?.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs="12"
                  lg="6"
                  className="mb-3"
                  controlId="organizationForm.country"
                >
                  <Form.Label>
                    {t('organization:form.country')}{' '}
                    {requiredFields.includes('country') && (
                      <span className="text-danger">*</span>
                    )}
                  </Form.Label>
                  <SelectComponent
                    options={countries}
                    placeholder={t('organization:form.country')}
                    classNamePrefix="react-select"
                    value={
                      formData?.country
                        ? countries.find(
                            c => c.code === formData?.country?.code
                          )
                        : null
                    }
                    onChange={value => handleChange('country', value)}
                    errorText={errors?.country?.message || ''}
                    isDisabled={editDisabled}
                    invalid={!!errors?.country?.invalid}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs="12"
                  lg="6"
                  className="mb-3"
                  controlId="organizationForm.city"
                >
                  <Form.Label>
                    {t('organization:form.city')}{' '}
                    {requiredFields.includes('city') && (
                      <span className="text-danger">*</span>
                    )}
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      type="text"
                      placeholder={t('organization:form.city')}
                      onChange={e => handleChange('city', e.target.value)}
                      isInvalid={errors?.city?.invalid}
                      value={formData?.city || formData?.city || ''}
                      disabled={editDisabled}
                    />

                    {errors?.city?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors?.city?.message}
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs="12"
                  className="mb-3"
                  controlId="organizationForm.address"
                >
                  <Form.Label>
                    {t('organization:form.address')}{' '}
                    {requiredFields.includes('address') && (
                      <span className="text-danger">*</span>
                    )}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    placeholder={t('organization:form.address')}
                    onChange={e => handleChange('address', e.target.value)}
                    isInvalid={errors?.address?.invalid}
                    value={formData?.address || ''}
                    disabled={editDisabled}
                  />
                  {errors?.address?.message && (
                    <Form.Control.Feedback type="invalid">
                      {errors?.address?.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs="12"
                  className="mb-3"
                  controlId="organizationForm.location"
                >
                  <Form.Label>
                    {t('organization:form.locationOnMap')}{' '}
                    {requiredFields.includes('location') && (
                      <span className="text-danger">*</span>
                    )}
                  </Form.Label>
                  {/* <MapComponent
                    disabled={editDisabled}
                    lang={currentLanguage}
                    onLocationChange={loc => handleChange('location', loc)}
                    onPositionChange={pos =>
                      console.log('onPositionChange', pos)
                    }
                    location={}
                  /> */}
                  <Form.Control
                    type="text"
                    placeholder={t('organization:placeholders.locationOnMap')}
                    onChange={e => handleChange('location', e.target.value)}
                    isInvalid={errors?.location?.invalid}
                    value={formData?.location?.link || ''}
                    disabled={editDisabled}
                    className="mt-2"
                  />
                  {errors?.location?.message && (
                    <Form.Control.Feedback type="invalid">
                      {errors?.location?.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
              <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
                <Col
                  xs="auto"
                  className="navbar-vertical-label navbar-vertical-label"
                >
                  {t('organization:form.contactInfo')}
                </Col>
                <Col className="ps-0">
                  <hr className="mb-0 navbar-vertical-divider"></hr>
                </Col>
              </Row>
              <Row>
                <Form.Group
                  as={Col}
                  xs="12"
                  lg="6"
                  className="mb-3"
                  controlId="organizationForm.contactPhone"
                >
                  <Form.Label>
                    {t('organization:form.contactPhone')}{' '}
                    {requiredFields.includes('contactPhone') && (
                      <span className="text-danger">*</span>
                    )}
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      type="text"
                      placeholder={t('organization:form.contactPhone')}
                      onChange={e =>
                        handleChange('contactPhone', e.target.value)
                      }
                      isInvalid={errors?.contactPhone?.invalid}
                      value={
                        formData?.contactPhone || formData?.contactPhone || ''
                      }
                      disabled={editDisabled}
                    />

                    {errors?.contactPhone?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors?.contactPhone?.message}
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs="12"
                  lg="6"
                  className="mb-3"
                  controlId="organizationForm.additionalContactPhone"
                >
                  <Form.Label>
                    {t('organization:form.additionalContactPhone')}
                    {requiredFields.includes('additionalContactPhone') && (
                      <span className="text-danger">*</span>
                    )}
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      type="text"
                      placeholder={t(
                        'organization:form.additionalContactPhone'
                      )}
                      onChange={e =>
                        handleChange('additionalContactPhone', e.target.value)
                      }
                      isInvalid={errors?.additionalContactPhone?.invalid}
                      value={
                        formData?.additionalContactPhone ||
                        formData?.additionalContactPhone ||
                        ''
                      }
                      disabled={editDisabled}
                    />

                    {errors?.additionalContactPhone?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors?.additionalContactPhone?.message}
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group
                  as={Col}
                  xs="12"
                  className="mb-3"
                  controlId="organizationForm.contactEmail"
                >
                  <Form.Label>
                    {t('organization:form.contactEmail')}{' '}
                    {requiredFields.includes('contactEmail') && (
                      <span className="text-danger">*</span>
                    )}
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      type="text"
                      placeholder={t('organization:form.contactEmail')}
                      onChange={e =>
                        handleChange('contactEmail', e.target.value)
                      }
                      isInvalid={errors?.contactEmail?.invalid}
                      value={
                        formData?.contactEmail || formData?.contactEmail || ''
                      }
                      disabled={editDisabled}
                    />

                    {errors?.contactEmail?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors?.contactEmail?.message}
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
                <Col
                  xs="auto"
                  className="navbar-vertical-label navbar-vertical-label"
                >
                  {t('organization:form.socialInfo')}
                </Col>
                <Col className="ps-0">
                  <hr className="mb-0 navbar-vertical-divider"></hr>
                </Col>
              </Row>
              <Row>
                <Form.Group
                  as={Col}
                  xs="12"
                  className="mb-3"
                  controlId="organizationForm.country"
                >
                  <Form.Label>{t('organization:form.socialLinks')}</Form.Label>
                  <SelectComponent
                    options={socialPlatforms.map(c => ({
                      label: t(`common:socialPlatforms.${c}`),
                      value: c
                    }))}
                    isMulti
                    placeholder={t('organization:form.socialLinks')}
                    classNamePrefix="react-select"
                    value={
                      formData?.socialLinks?.map(c => ({
                        label: t(`common:socialPlatforms.${c.socialPlatform}`),
                        value: c.socialPlatform
                      })) || []
                    }
                    onChange={value => handleChange('socialLinks', value)}
                    isDisabled={editDisabled}
                  />
                </Form.Group>
              </Row>
              <Row>
                {formData?.socialLinks?.map((socialLink, index) => {
                  return (
                    <Row
                      key={`socialLink-${socialLink.socialPlatform}-${index}`}
                      className="align-items-center mt-1"
                    >
                      <Col md="1">
                        <FontAwesomeIcon
                          icon={getSocialIcon(socialLink.socialPlatform)}
                          size="2x"
                        />
                      </Col>
                      <Col md="11">
                        <Form.Group
                          as={Col}
                          xs="12"
                          className=""
                          controlId="organizationForm.socialLink"
                        >
                          <InputGroup className="">
                            <Form.Control
                              type="text"
                              placeholder={t(
                                `common:socialPlatforms.${socialLink.socialPlatform}`
                              )}
                              onChange={e =>
                                handleChange('socialLinkValue', {
                                  platform: socialLink.socialPlatform,
                                  link: e.target.value
                                })
                              }
                              isInvalid={
                                errors?.[socialLink.socialPlatform]?.invalid
                              }
                              value={socialLink?.link || ''}
                              disabled={editDisabled}
                            />

                            {errors?.[socialLink.socialPlatform]?.message && (
                              <Form.Control.Feedback type="invalid">
                                {errors?.[socialLink.socialPlatform]?.message}
                              </Form.Control.Feedback>
                            )}
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>
                  );
                })}
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className={breakpoints.down('md') ? 'mt-2' : ''}>
          <Card className="position-sticky" style={{ top: 70 }}>
            <Card.Body>
              {editDisabled && !loading && (
                <Button
                  disabled={loading}
                  onClick={() => setEditDisabled(false)}
                >
                  {t(`common:button.edit`)}
                </Button>
              )}
              {!editDisabled && (
                <>
                  <Button disabled={loading} onClick={() => handleSubmit()}>
                    {t(`common:button.save`)}
                  </Button>
                  <Button
                    variant="gray"
                    disabled={loading}
                    onClick={() => handleCancel()}
                    className="mx-2"
                  >
                    {t(`common:button.cancel`)}
                  </Button>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-2 gx-3">
        <Col xs={12}>
          <Card>
            <Card.Header className="d-flex align-items-center justify-content-between w-100">
              {t('organization:labels.branches')}
              <Button variant="light" onClick={() => setBranchModalOpen(true)}>
                <FontAwesomeIcon icon={faPlus} />
                {` ${t('organization:button.newBranch')}`}
              </Button>
            </Card.Header>
            <Card.Body>
              <AdvanceTableWrapper
                columns={columns}
                data={organization?.branches || []}
                sortable
                pagination
                perPage={10}
                rowCount={organization?.branches?.length || 0}
                manualSortBy
              >
                <AdvanceTable
                  table
                  headerClassName="bg-light text-800 align-middle"
                  rowClassName="btn-reveal-trigger align-top"
                  tableProps={{
                    bordered: true,
                    striped: true,
                    hover: true
                  }}
                />
              </AdvanceTableWrapper>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {!!confirmModalProps && (
        <ConfirmModal {...confirmModalProps} loading={loading} />
      )}
      <BranchModal
        parentOrganization={organization}
        isOpen={branchModalOpen}
        organization={branchToEdit}
        refetch={fetchOrganization}
        onClose={() => {
          setBranchModalOpen(false);
          setBranchToEdit(null);
        }}
      />
    </div>
  );
};

export default OrganizationSettings;
