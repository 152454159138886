import http from 'http/interceptor';
import { getItemFromStore } from 'helpers/utils';
const currentLocale =
  getItemFromStore('preferredLanguage', 'en') ||
  process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/${currentLocale}/${process.env.REACT_APP_API_VERSION}`;
const LIST_CAMPAIGNS_URL = `${BASE_API_URL}/campaign/list`;
const GET_CAMPAIGN_URL = `${BASE_API_URL}/campaign/get`;
const LIST_CAMPAIGN_AVAILABLE_TRANSITIONS_URL = `${BASE_API_URL}/campaign/transitions/list`;
const SAVE_CAMPAIGN_URL = `${BASE_API_URL}/campaign/save`;
const LIST_PACKAGES_URL = `${BASE_API_URL}/campaign/packages/list`;
const GET_PACKAGE_URL = `${BASE_API_URL}/campaign/packages/get`;
const DO_ACTION_URL = `${BASE_API_URL}/campaign/dotransition`;
const DELETE_PRODUCT_URL = `${BASE_API_URL}/campaign/products/delete`;

const CampaignService = {
  list: payload => {
    const url = `${LIST_CAMPAIGNS_URL}`;

    return http.post(url, payload, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  get: id => {
    const url = `${GET_CAMPAIGN_URL}?id=${id}`;

    return http.get(url, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  listAvailableTransitions: id => {
    const url = `${LIST_CAMPAIGN_AVAILABLE_TRANSITIONS_URL}?id=${id}`;

    return http.get(url, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  saveCampaign: data => {
    const url = `${SAVE_CAMPAIGN_URL}`;
    console.log('saveCampaign', data);
    const formData = new FormData();
    if (data.id) {
      formData.append('id', data.id);
    }

    //basic form
    formData.append('packageId', data.packageId);
    formData.append('title[ar]', data.title.ar);
    formData.append('title[en]', data.title.en);
    formData.append('description[ar]', data.description.ar);
    formData.append('description[en]', data.description.en);
    formData.append('startDate', data.startDate);
    formData.append('endDate', data.endDate);
    formData.append('businessType', data.businessType);
    formData.append('type', data.type);

    //prizes
    for (let index = 0; index < data.prizes?.length; index++) {
      const prize = data.prizes[index];
      formData.append(`prizes[${index}][type]`, prize.type);

      for (let y = 0; y < prize.groups?.length; y++) {
        const group = prize.groups[y];
        formData.append(`prizes[${index}][groups][${y}][type]`, group.type);
        formData.append(
          `prizes[${index}][groups][${y}][percentage]`,
          group.percentage
        );
        formData.append(
          `prizes[${index}][groups][${y}][valuePercentage]`,
          group.valuePercentage
        );
        formData.append(
          `prizes[${index}][groups][${y}][count]`,
          group.count ?? 0
        );
        formData.append(
          `prizes[${index}][groups][${y}][value]`,
          group.value ?? 0
        );
      }
    }

    //targets
    if (data.target) {
      formData.append('target[age][to]', data.target.age.to);
      formData.append('target[age][from]', data.target.age.from);
      formData.append('target[gender]', data.target.gender);

      formData.append(
        'target[locations][0][city][name][ar]',
        data.target.locations[0].city.name.ar
      );
      formData.append(
        'target[locations][0][city][name][en]',
        data.target.locations[0].city.name.en
      );
      formData.append(
        'target[locations][0][city][countryCode]',
        data.target.locations[0].city.countryCode
      );
      formData.append(
        'target[locations][0][city][id]',
        data.target.locations[0].city.id
      );
      formData.append(
        'target[locations][0][city][latitude]',
        data.target.locations[0].city.latitude
      );
      formData.append(
        'target[locations][0][city][longitude]',
        data.target.locations[0].city.longitude
      );

      formData.append(
        'target[locations][0][country][id]',
        data.target.locations[0].country.id
      );
      formData.append(
        'target[locations][0][country][name][ar]',
        data.target.locations[0].country.name.ar
      );
      formData.append(
        'target[locations][0][country][name][en]',
        data.target.locations[0].country.name.en
      );
      formData.append(
        'target[locations][0][country][code]',
        data.target.locations[0].country.code
      );
      formData.append(
        'target[locations][0][country][IsoCode]',
        data.target.locations[0].country.IsoCode
      );

      formData.append(
        'target[locations][0][location]',
        data.target.locations[0].city.name.en
      );
      formData.append(
        'target[locations][0][longitude]',
        data.target.locations[0].longitude
      );
      formData.append(
        'target[locations][0][latitude]',
        data.target.locations[0].latitude
      );
    }

    if (data.products?.length) {
      for (let index = 0; index < data.products.length; index++) {
        const element = data.products[index];
        formData.append(`products[${index}][source]`, element.source || '');
        formData.append(`products[${index}].file`, element.file || '');
        formData.append(`products[${index}][label]`, element.label || '');
      }
    } else {
      formData.append(`products`, null);
    }
    if (data.organizations?.length) {
      for (let index = 0; index < data.organizations.length; index++) {
        const element = data.organizations[index];
        formData.append(`organizations[${index}]`, element);
      }
    }

    return http.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  },
  listPackages: () => {
    const url = `${LIST_PACKAGES_URL}`;

    return http.get(url, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  getPackage: id => {
    const url = `${GET_PACKAGE_URL}?id=${id}`;

    return http.get(url, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  doAction: (id, action) => {
    const url = `${DO_ACTION_URL}`;

    return http.post(
      url,
      { id, action },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  deleteProduct: (campaignId, product) => {
    const url = `${DELETE_PRODUCT_URL}`;
    return http.post(
      url,
      { source: product.source, campaignId },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  }
};

export default CampaignService;
