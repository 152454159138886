import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import CreateCampaignHeader from './CreateCampaignHeader';
import { AuthContext } from 'context/Context';
import CompanyInfoCard from './CompanyInfoCard';
import CampaignBasicInfoCard from './CampaignBasicInfoCard';
import CampaignActionCard from './CampaignActionCard';
import CampaignProductsCard from './CampaignProductsCard';
import CampaignTarget from './CampaignTarget';
import CampaignPrizes from './CampaignPrizes';

const CreateCampaign = () => {
  const { user } = useContext(AuthContext);

  return (
    <Row className="g-lg-3 font-sans-serif">
      <Col lg={8}>
        <CreateCampaignHeader />
        <CampaignBasicInfoCard />
        <CompanyInfoCard organizationId={user.organizationId} />
        <CampaignPrizes />
        <CampaignProductsCard />
      </Col>
      <Col lg={4}>
        <div className="position-sticky" style={{ top: 70 }}>
          <CampaignActionCard />
          <CampaignTarget />
        </div>
      </Col>
    </Row>
  );
};

export default CreateCampaign;
