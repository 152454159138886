import SpinnerCardHeader from 'components/common/SpinnerCardHeader';
import React, { useState, useContext } from 'react';
import {
  Button,
  Card,
  Form,
  InputGroup,
  OverlayTrigger,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import UserService from 'http/UserService';
import { getErrorMessage } from 'http/utils';
import { isPasswordComplex } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import AppContext from 'context/Context';

const ChangePassword = ({ user }) => {
  const { t } = useTranslation();
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = () => {
    const isValid = handleValidation(formData);
    if (isValid) {
      setLoading(true);
      UserService.requestPasswordChange({ ...formData, userId: user.id })
        .then(response => {
          if (response) {
            toast.success(t('user:message.changePasswordSuccess'), {
              theme: 'colored'
            });
            setFormData({
              oldPassword: '',
              newPassword: '',
              confirmPassword: ''
            });
          } else {
            toast.error(getErrorMessage(t, response), {
              theme: 'colored'
            });
          }
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          toast.error(getErrorMessage(t, error), {
            theme: 'colored'
          });
        });
    } else {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored'
      });
    }
  };

  const handleValidation = data => {
    let errorsValues = {};
    Object.entries(data).map(([key, value]) => {
      if (!value) {
        errorsValues[key] = {
          invalid: true,
          message: t('common:validation.fieldIsRequired')
        };
        return;
      } else {
        errorsValues[key] = { invalid: false };
      }
      return false;
    });
    if (data.newPassword !== data.confirmPassword) {
      errorsValues.confirmPassword = {
        invalid: true,
        message: t('user:validation.passwordAndConfirmPasswordMismatch')
      };
    }
    if (!isPasswordComplex(data.newPassword)) {
      errorsValues.newPassword = {
        invalid: true,
        message: t('user:validation.passwordDoesNotMeetCriteria')
      };
    }
    console.log('errors', errorsValues);
    setErrors(errorsValues);
    return Object.values(errorsValues).every(er => !er?.invalid);
  };

  return (
    <Card className="mb-3">
      <SpinnerCardHeader title={t('common:button.changePassword')} />
      <Card.Body className="bg-light">
        <Form>
          <Form.Group className="mb-3" controlId="oldPassword">
            <Form.Label>
              {t('user:labels.oldPassword')}
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={formData.oldPassword}
              name="oldPassword"
              onChange={handleChange}
              isInvalid={errors?.oldPassword?.invalid}
            />
            {errors?.oldPassword.message && (
              <Form.Control.Feedback type="invalid">
                {errors?.oldPassword.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="newPassword">
            <Form.Label>
              {t('user:labels.newPassword')}
              <span className="text-danger">*</span>
            </Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                type="text"
                value={formData.newPassword}
                name="newPassword"
                onChange={handleChange}
                isInvalid={errors?.newPassword?.invalid}
              />
              <OverlayTrigger
                placement={isRTL ? 'right' : 'left'}
                style={{ width: '500px' }}
                overlay={
                  <Tooltip
                    id="user-password-tooltip"
                    style={{
                      position: 'absolute!important'
                    }}
                  >
                    <p
                      className="text-start break-spaces"
                      dangerouslySetInnerHTML={{
                        __html: `${t('user:tooltips.passwordComplexCriteria')}`
                      }}
                    ></p>
                  </Tooltip>
                }
              >
                <span>
                  <InputGroup.Text className="h-100">
                    <FontAwesomeIcon icon={faExclamationCircle} />
                  </InputGroup.Text>
                </span>
              </OverlayTrigger>
              {errors?.newPassword.message && (
                <Form.Control.Feedback type="invalid">
                  {errors?.newPassword.message}
                </Form.Control.Feedback>
              )}
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="confirmPassword">
            <Form.Label>
              {t('user:labels.confirmPassword')}
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={formData.confirmPassword}
              name="confirmPassword"
              onChange={handleChange}
              isInvalid={errors?.confirmPassword?.invalid}
            />
            {errors?.confirmPassword.message && (
              <Form.Control.Feedback type="invalid">
                {errors?.confirmPassword.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Button
            className="w-100"
            type="button"
            disabled={loading}
            onClick={handleSubmit}
          >
            {loading && <Spinner size="sm" />}
            {!loading && t('common:button.update')}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ChangePassword;
