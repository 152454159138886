import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import SpinnerCloseButton from 'components/common/SpinnerCloseButton';
import { useTranslation } from 'react-i18next';
import SelectComponent from 'components/common/SelectComponent';
import { toast } from 'react-toastify';
import { getErrorMessage, toFileUrl } from 'http/utils';
import { validateEmail, validatePhoneNumber } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faGlobe
} from '@fortawesome/free-solid-svg-icons';
import Avatar from 'components/common/Avatar';
import defaultAvatar from 'assets/img/team/avatar.png';
import { useMutation } from 'react-query';
import FileService from 'http/FileService';
import { useContext } from 'react';
import { DataContext } from 'context/Context';
import OrganizationService from 'http/OrganizationService';
import {
  faFacebook,
  faInstagram,
  faSnapchat,
  faTiktok,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';

const initFormData = {
  name: null,
  description: null,
  address: null,
  city: null,
  contactEmail: null,
  contactPhone: null,
  status: null,
  country: null,
  avatarUrl: null,
  additionalContactPhone: null,
  avatar: null,
  parentId: null,
  socialLinks: [],
  location: null
};

const requiredFields = [
  'name',
  'country',
  'city',
  'contactPhone',
  'contactEmail',
  'location'
];

const socialPlatforms = [
  'facebook',
  'youtube',
  'instagram',
  'snapchat',
  'tiktok',
  'x',
  'web'
];

const getSocialIcon = platform => {
  switch (platform) {
    case 'facebook':
      return faFacebook;
    case 'youtube':
      return faYoutube;
    case 'instagram':
      return faInstagram;
    case 'snapchat':
      return faSnapchat;
    case 'tiktok':
      return faTiktok;
    case 'x':
      return faTwitter;

    default:
      return faGlobe;
  }
};

const OrganizationModal = ({ isOpen, onClose, organization, refetch }) => {
  const { t, i18n } = useTranslation();
  const { countries } = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [formData, setFormData] = useState({ ...organization });
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (!!organization) {
      setFormData(organization);
    }
    if (!organization) {
      setFormData(initFormData);
    }
  }, [organization]);

  const handleChange = (name, value) => {
    if (name === 'socialLinks') {
      setFormData({
        ...formData,
        socialLinks: value.map(s => ({ link: '', socialPlatform: s.value }))
      });

      return;
    }

    if (name === 'location') {
      setFormData({
        ...formData,
        location: {
          link: value
        }
      });

      return;
    }

    if (name === 'socialLinkValue') {
      let newData = [...formData?.socialLinks];
      for (let index = 0; index < newData.length; index++) {
        if (newData[index]?.socialPlatform === value.platform) {
          newData[index].link = value.link;
        }
      }
      setFormData({
        ...formData,
        socialLinks: newData
      });

      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const formDataJson = JSON.stringify({ ...formData });
    const userDataJson = JSON.stringify({ ...organization });
    if (formDataJson !== userDataJson) {
      setHasChange(true);
    } else {
      setHasChange(false);
    }
  }, [formData]);

  const handleSubmit = () => {
    console.log('handleSubmit', { formData });
    const hasError = handleValidateFormData(formData);

    if (hasError) {
      return;
    }

    setLoading(true);

    let payload = {
      ...formData,
      id: organization?.id ?? null,
      countryCode: formData.country.code,
      status: !!organization?.id ? organization?.status : 'active'
    };

    delete payload['country'];
    delete payload['avatarPreview'];

    OrganizationService.create({ ...payload })
      .then(response => {
        setLoading(false);
        refetch && refetch();
        toast.success(
          t(
            `organization:message.${
              !!organization?.id ? 'updateSuccess' : 'createSuccess'
            }`
          )
        );
        handleCancel();
      })
      .catch(error => {
        setLoading(false);
        toast.error(getErrorMessage(error));
      });
  };

  const handleUploadImage = event => {
    const file = event?.target?.files?.[0];
    console.log('file', file);
    if (!file) {
      return;
    }
    const objectUrl = URL.createObjectURL(file);
    setFormData({ ...formData, avatar: file, avatarPreview: objectUrl });
  };

  const handleValidateFormData = data => {
    if (!data) {
      return true;
    }
    let errorsObj = null;
    Object.entries(data).map(([key, value]) => {
      if (key === 'metadata') {
        return;
      }
      if (key === 'contactEmail' && !!value) {
        if (!validateEmail(value)) {
          errorsObj = {
            ...errorsObj,
            [key]: {
              invalid: true,
              message: t('common:validation.invalidEmail')
            }
          };
          return;
        }
      }
      if (key === 'location') {
        if (!value?.link) {
          errorsObj = {
            ...errorsObj,
            [key]: {
              invalid: true,
              message: t('common:validation.fieldIsRequired')
            }
          };
          return;
        } else {
          errorsObj = {
            ...errorsObj,
            [key]: null
          };
          return;
        }
      }
      if (
        (key === 'contactPhone' || key === 'additionalContactPhone') &&
        !!value
      ) {
        if (!validatePhoneNumber(`+${value}`)) {
          errorsObj = {
            ...errorsObj,
            [key]: {
              invalid: true,
              message: t('common:validation.invalidPhoneNumber')
            }
          };
          return;
        }
      }
      if (key === 'socialLinks') {
        value?.map(socialLink => {
          if (!socialLink.link) {
            errorsObj = {
              ...errorsObj,
              [socialLink.socialPlatform]: {
                invalid: true,
                message: t('common:validation.fieldIsRequired')
              }
            };
          } else {
            errorsObj = {
              ...errorsObj,
              [socialLink.socialPlatform]: null
            };
          }
        });
      }
      if (!value && requiredFields.includes(key)) {
        errorsObj = {
          ...errorsObj,
          [key]: {
            invalid: true,
            message: t('common:validation.fieldIsRequired')
          }
        };
        return;
      } else {
        errorsObj = { ...errorsObj, [key]: null };
        return;
      }
    });
    setErrors(errorsObj);
    let inError = false;
    if (Object.values(errorsObj).some(val => !!val)) {
      inError = true;
    }
    console.log('errorsObj', errorsObj);
    return inError;
  };

  const handleCancel = () => {
    setFormData({ ...organization });
    setErrors(null);
    onClose && onClose(false);
  };

  return (
    <Modal
      show={isOpen}
      onHide={handleCancel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t(
            `organization:labels.${
              !!organization
                ? 'organizationEditModalHeader'
                : 'organizationCreateModalHeader'
            }`,
            { name: organization?.name }
          )}
        </Modal.Title>
        <SpinnerCloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={handleCancel}
        />
      </Modal.Header>
      <Modal.Body>
        <Row className="w-100 gx-0 gy-1">
          <Col md={12} className="mt-1 g-1 text-center">
            <Avatar
              ref={undefined}
              size="4xl"
              src={
                formData?.avatarPreview
                  ? formData?.avatarPreview
                  : formData?.avatarUrl
                  ? toFileUrl(formData?.avatarUrl)
                  : defaultAvatar
              }
              loading={loading}
              enableUpload
              onUpload={file => handleUploadImage(file)}
              overlayClassName="no-border"
              tooltip={t('organization:form.logo')}
              tooltipPlacement="bottom"
              mediaClass={`${errors?.avatarUrl?.error ? 'border-danger' : ''}`}
            />
          </Col>
          <Form.Text className="text-danger w-100 text-center">
            {!!errors?.avatarUrl?.error &&
              t('common:validation.imageIsRequired')}
          </Form.Text>
        </Row>
        <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
          <Col
            xs="auto"
            className="navbar-vertical-label navbar-vertical-label"
          >
            {t('organization:form.basicInfo')}
          </Col>
          <Col className="ps-0">
            <hr className="mb-0 navbar-vertical-divider"></hr>
          </Col>
        </Row>
        <Row>
          <Form.Group
            as={Col}
            xs="12"
            //lg="4"
            className="mb-3"
            controlId="organizationForm.name"
          >
            <Form.Label>
              {t('organization:form.name')}{' '}
              {requiredFields.includes('name') && (
                <span className="text-danger">*</span>
              )}
            </Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                type="text"
                placeholder={t('organization:form.name')}
                onChange={e => handleChange('name', e.target.value)}
                isInvalid={errors?.name?.invalid}
                value={formData?.name || formData?.name || ''}
                disabled={loading}
              />

              {errors?.name?.message && (
                <Form.Control.Feedback type="invalid">
                  {errors?.name?.message}
                </Form.Control.Feedback>
              )}
            </InputGroup>
          </Form.Group>
          <Form.Group
            as={Col}
            xs="12"
            className="mb-3"
            controlId="organizationForm.description"
          >
            <Form.Label>
              {t('organization:form.description')}
              {requiredFields.includes('description') && (
                <span className="text-danger">*</span>
              )}
            </Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              placeholder={t('organization:form.description')}
              onChange={e => handleChange('description', e.target.value)}
              isInvalid={errors?.description?.invalid}
              value={formData?.description || ''}
              disabled={loading}
            />
            {errors?.description?.message && (
              <Form.Control.Feedback type="invalid">
                {errors?.description?.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group
            as={Col}
            xs="12"
            lg="6"
            className="mb-3"
            controlId="organizationForm.country"
          >
            <Form.Label>
              {t('organization:form.country')}{' '}
              {requiredFields.includes('country') && (
                <span className="text-danger">*</span>
              )}
            </Form.Label>
            <SelectComponent
              options={countries}
              placeholder={t('organization:form.country')}
              classNamePrefix="react-select"
              value={
                formData?.country
                  ? countries.find(c => c.code === formData?.country.code)
                  : null
              }
              onChange={value => handleChange('country', value)}
              errorText={errors?.country?.message || ''}
              invalid={!!errors?.country?.invalid}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            xs="12"
            lg="6"
            className="mb-3"
            controlId="organizationForm.city"
          >
            <Form.Label>
              {t('organization:form.city')}{' '}
              {requiredFields.includes('city') && (
                <span className="text-danger">*</span>
              )}
            </Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                type="text"
                placeholder={t('organization:form.city')}
                onChange={e => handleChange('city', e.target.value)}
                isInvalid={errors?.city?.invalid}
                value={formData?.city || formData?.city || ''}
                disabled={loading}
              />

              {errors?.city?.message && (
                <Form.Control.Feedback type="invalid">
                  {errors?.city?.message}
                </Form.Control.Feedback>
              )}
            </InputGroup>
          </Form.Group>
          <Form.Group
            as={Col}
            xs="12"
            className="mb-3"
            controlId="organizationForm.address"
          >
            <Form.Label>
              {t('organization:form.address')}{' '}
              {requiredFields.includes('address') && (
                <span className="text-danger">*</span>
              )}
            </Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              placeholder={t('organization:form.address')}
              onChange={e => handleChange('address', e.target.value)}
              isInvalid={errors?.address?.invalid}
              value={formData?.address || ''}
              disabled={loading}
            />
            {errors?.address?.message && (
              <Form.Control.Feedback type="invalid">
                {errors?.address?.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group
            as={Col}
            xs="12"
            className="mb-3"
            controlId="organizationForm.location"
          >
            <Form.Label>
              {t('organization:form.locationOnMap')}{' '}
              {requiredFields.includes('location') && (
                <span className="text-danger">*</span>
              )}
            </Form.Label>
            {/* <MapComponent
                    disabled={editDisabled}
                    lang={currentLanguage}
                    onLocationChange={loc => handleChange('location', loc)}
                    onPositionChange={pos =>
                      console.log('onPositionChange', pos)
                    }
                    location={}
                  /> */}
            <Form.Control
              type="text"
              placeholder={t('organization:placeholders.locationOnMap')}
              onChange={e => handleChange('location', e.target.value)}
              isInvalid={errors?.location?.invalid}
              value={formData?.location?.link || ''}
              disabled={loading}
              className="mt-2"
            />
            {errors?.location?.message && (
              <Form.Control.Feedback type="invalid">
                {errors?.location?.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Row>
        <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
          <Col
            xs="auto"
            className="navbar-vertical-label navbar-vertical-label"
          >
            {t('organization:form.contactInfo')}
          </Col>
          <Col className="ps-0">
            <hr className="mb-0 navbar-vertical-divider"></hr>
          </Col>
        </Row>
        <Row>
          <Form.Group
            as={Col}
            xs="12"
            lg="6"
            className="mb-3"
            controlId="organizationForm.contactPhone"
          >
            <Form.Label>
              {t('organization:form.contactPhone')}{' '}
              {requiredFields.includes('contactPhone') && (
                <span className="text-danger">*</span>
              )}
            </Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                type="text"
                placeholder={t('organization:form.contactPhone')}
                onChange={e => handleChange('contactPhone', e.target.value)}
                isInvalid={errors?.contactPhone?.invalid}
                value={formData?.contactPhone || formData?.contactPhone || ''}
                disabled={loading}
              />

              {errors?.contactPhone?.message && (
                <Form.Control.Feedback type="invalid">
                  {errors?.contactPhone?.message}
                </Form.Control.Feedback>
              )}
            </InputGroup>
          </Form.Group>
          <Form.Group
            as={Col}
            xs="12"
            lg="6"
            className="mb-3"
            controlId="organizationForm.additionalContactPhone"
          >
            <Form.Label>
              {t('organization:form.additionalContactPhone')}
              {requiredFields.includes('additionalContactPhone') && (
                <span className="text-danger">*</span>
              )}
            </Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                type="text"
                placeholder={t('organization:form.additionalContactPhone')}
                onChange={e =>
                  handleChange('additionalContactPhone', e.target.value)
                }
                isInvalid={errors?.additionalContactPhone?.invalid}
                value={
                  formData?.additionalContactPhone ||
                  formData?.additionalContactPhone ||
                  ''
                }
                disabled={loading}
              />

              {errors?.additionalContactPhone?.message && (
                <Form.Control.Feedback type="invalid">
                  {errors?.additionalContactPhone?.message}
                </Form.Control.Feedback>
              )}
            </InputGroup>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group
            as={Col}
            xs="12"
            className="mb-3"
            controlId="organizationForm.contactEmail"
          >
            <Form.Label>
              {t('organization:form.contactEmail')}{' '}
              {requiredFields.includes('contactEmail') && (
                <span className="text-danger">*</span>
              )}
            </Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                type="text"
                placeholder={t('organization:form.contactEmail')}
                onChange={e => handleChange('contactEmail', e.target.value)}
                isInvalid={errors?.contactEmail?.invalid}
                value={formData?.contactEmail || formData?.contactEmail || ''}
                disabled={loading}
              />

              {errors?.contactEmail?.message && (
                <Form.Control.Feedback type="invalid">
                  {errors?.contactEmail?.message}
                </Form.Control.Feedback>
              )}
            </InputGroup>
          </Form.Group>
        </Row>
        <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
          <Col
            xs="auto"
            className="navbar-vertical-label navbar-vertical-label"
          >
            {t('organization:form.socialInfo')}
          </Col>
          <Col className="ps-0">
            <hr className="mb-0 navbar-vertical-divider"></hr>
          </Col>
        </Row>
        <Row>
          <Form.Group
            as={Col}
            xs="12"
            className="mb-3"
            controlId="organizationForm.country"
          >
            <Form.Label>{t('organization:form.socialLinks')}</Form.Label>
            <SelectComponent
              options={socialPlatforms.map(c => ({
                label: t(`common:socialPlatforms.${c}`),
                value: c
              }))}
              isMulti
              placeholder={t('organization:form.socialLinks')}
              classNamePrefix="react-select"
              value={
                formData?.socialLinks?.map(c => ({
                  label: t(`common:socialPlatforms.${c.socialPlatform}`),
                  value: c.socialPlatform
                })) || []
              }
              onChange={value => handleChange('socialLinks', value)}
            />
          </Form.Group>
        </Row>
        <Row>
          {formData?.socialLinks?.map((socialLink, index) => {
            return (
              <Row
                key={`socialLink-${socialLink.socialPlatform}-${index}`}
                className="align-items-center mt-1"
              >
                <Col md="1">
                  <FontAwesomeIcon
                    icon={getSocialIcon(socialLink.socialPlatform)}
                    size="2x"
                  />
                </Col>
                <Col md="11">
                  <Form.Group
                    as={Col}
                    xs="12"
                    className=""
                    controlId="organizationForm.socialLink"
                  >
                    <InputGroup className="">
                      <Form.Control
                        type="text"
                        placeholder={t(
                          `common:socialPlatforms.${socialLink.socialPlatform}`
                        )}
                        onChange={e =>
                          handleChange('socialLinkValue', {
                            platform: socialLink.socialPlatform,
                            link: e.target.value
                          })
                        }
                        isInvalid={errors?.[socialLink.socialPlatform]?.invalid}
                        value={socialLink?.link || ''}
                        disabled={loading}
                      />

                      {errors?.[socialLink.socialPlatform]?.message && (
                        <Form.Control.Feedback type="invalid">
                          {errors?.[socialLink.socialPlatform]?.message}
                        </Form.Control.Feedback>
                      )}
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            );
          })}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCancel} variant="gray" disabled={loading}>
          {t('common:button.cancel')}
        </Button>
        <Button
          onClick={handleSubmit}
          variant={'primary'}
          disabled={loading || !hasChange}
        >
          {!loading && t('common:button.save')}
          {loading && <Spinner animation="border" role="status" size="sm" />}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrganizationModal;
