import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import LoaderWithMessage from 'components/common/LoaderWithMessage';
import ErrorPlaceholder from 'components/common/ErrorPlaceholder';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Offcanvas,
  Placeholder,
  Row
} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { useBreakpoints } from 'hooks/useBreakpoints';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmModal from 'components/common/ConfirmModal';
import { getErrorMessage } from 'http/utils';
import { getCampaignStateBadge } from 'helpers/utils';
import { AuthContext } from 'context/Context';
import CampaignService from 'http/CampaignService';
import CampaignsListFilter from './CampaignsListFilter';
import CampaignsListHeader from './CampaignsListHeader';
import PackageModal from './PackageModal';

const defaultPaging = {
  pageNumber: 1,
  pageSize: 10
};

const defaultSorting = {
  sortBy: 'metadata.createdAt',
  sortDirection: 'DESC'
};

const CampaignsList = () => {
  const { isAdmin, isManager, user } = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const currentLanguage = i18n.language;
  const [campaigns, setCampaigns] = useState([]);
  const [pagingInfo, setPagingInfo] = useState(null);
  const [sortInfo, setSortInfo] = useState({ ...defaultSorting });
  const [filters, setFilters] = useState({
    ...defaultPaging,
    ...defaultSorting
  });
  const [show, setShow] = useState(false);
  const { breakpoints } = useBreakpoints();
  const [confirmModalProps, setConfirmModalProps] = useState(null);
  const [requestLoading, setRequestLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    isLoading: campaignsLoading,
    error: campaignsError,
    data: campaignsData,
    refetch: fetchCampaigns
  } = useQuery(
    ['CampaignsList', { ...filters, ...sortInfo }],
    () => CampaignService.list({ ...filters, ...sortInfo }),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    fetchCampaigns({ ...filters, ...sortInfo, ...defaultPaging });
  }, [filters, sortInfo]);

  useEffect(() => {
    if (campaignsData && !campaignsLoading && !campaignsError) {
      setCampaigns(campaignsData.data);
      setPagingInfo(campaignsData.pagingInfo);
    }
    if (campaignsError) {
      toast.error(getErrorMessage(t, campaignsError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [campaignsLoading, campaignsError, campaignsData]);

  const handleSort = column => {
    //console.log('handleSort', column);
    const direction = sortInfo.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    setSortInfo({
      sortBy: column,
      sortDirection: direction
    });
  };

  const handleFilterChange = newFilters => {
    setFilters({
      ...newFilters
    });
  };

  const columns = [
    {
      accessor: 'title',
      Header: t('campaign:labels.title'),
      headerProps: {
        className: 'px-3',
        style: { height: '46px' },
        onClick: () => handleSort('NormalizedDisplayName')
      },
      cellProps: {
        className: 'py-2 white-space-nowrap px-3 pe-xxl-4'
      },
      Cell: rowData => {
        const { title, id, packageId } = rowData.row.original;
        return (
          <Flex alignItems="center" className="position-relative py-1 ">
            <Link
              to={`/campaign/${id}?packageId=${packageId}`}
              className="stretched-link fw-semi-bold"
            >
              {title?.[currentLanguage]}
            </Link>
          </Flex>
        );
      }
    },
    {
      accessor: 'startDate',
      Header: t(`campaign:table.startDate`),
      headerProps: {
        className: 'px-3',
        style: { height: '46px' },
        onClick: () => handleSort('startDate')
      },
      cellProps: {
        className: 'py-2 white-space-nowrap px-3 pe-xxl-4'
      },
      Cell: rowData => {
        const { startDate } = rowData.row.original;
        return (
          <p className=" py-2 ">{moment(startDate).format('DD/MM/YYYY')}</p>
        );
      }
    },
    {
      accessor: 'endDate',
      Header: t('campaign:table.endDate'),
      headerProps: {
        style: {
          minWidth: '14.625rem',
          width: '150px'
        },
        onClick: () => handleSort('endDate')
      },
      cellProps: {
        className: 'py-2 pe-4'
      },
      Cell: rowData => {
        const { endDate } = rowData.row.original;
        return <p className=" py-2 ">{moment(endDate).format('DD/MM/YYYY')}</p>;
      }
    },
    {
      accessor: 'state',
      Header: t('campaign:table.state'),
      headerProps: {
        onClick: () => handleSort('state')
      },
      cellProps: {
        className: 'fs-0 ',
        style: {
          width: '100px'
        }
      },
      Cell: rowData => {
        const { state } = rowData.row.original;
        return <>{getCampaignStateBadge(t, state)}</>;
      }
    },
    {
      accessor: 'metadata.createdAt',
      Header: t('campaign:table.createdAt'),
      headerProps: {
        className: 'text-start',
        onClick: () => handleSort('metadata.createdAt')
      },
      Cell: rowData => {
        const { metadata } = rowData.row.original;
        return (
          <p className="fw-semi-bold mb-0">
            {metadata?.createdAt
              ? moment(metadata?.createdAt || new Date())
                  .locale(currentLanguage)
                  .format('YYYY-MM-DD hh:mm A')
              : t('common:labels.noDataAvailable')}
          </p>
        );
      }
    },
    {
      accessor: 'action',
      Header: t('campaign:table.action'),
      headerProps: { className: 'text-start' },
      disableSortBy: true,
      exportable: false,
      Cell: rowData => {
        const { status, id, packageId } = rowData.row.original;
        return (
          <Dropdown
            align="end"
            className="btn-reveal-trigger d-inline-block mt-2"
          >
            <Dropdown.Toggle split variant="spinner-default" size="sm">
              <FontAwesomeIcon icon="ellipsis-h" className="fs--2" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="border py-0">
              <Dropdown.Item
                as={Link}
                to={`/campaign/${id}?packageId=${packageId}`}
              >
                {t('common:button.edit')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      }
    }
  ];

  if (campaignsLoading) {
    return (
      <LoaderWithMessage
        message={t('campaign:loading.loadingCampaigns')}
        className="mt-3"
      />
    );
  }

  if (campaignsError) {
    return (
      <ErrorPlaceholder
        message={t('campaign:loading.errorLoadingCampaigns')}
        className="mt-3"
      />
    );
  }

  return (
    <Row className="gx-3">
      <Col xxl={10} xl={9}>
        <Card>
          <Card.Header className="border-bottom border-200 px-0">
            <CampaignsListHeader
              table
              layout="table-view"
              handleShow={handleShow}
              handleSearch={searchTerm =>
                handleFilterChange({ search: searchTerm })
              }
              t={t}
              filters={filters}
              setFilters={setFilters}
              sortInfo={sortInfo}
              tableColumns={columns}
              totalCount={pagingInfo?.totalItems}
              organizationId={
                user.organization?.parentId ?? user.organization?.id
              }
            />
          </Card.Header>
          <Card.Body className={campaignsLoading ? '' : 'p-0'}>
            {campaignsLoading &&
              Array(14)
                .fill(1)
                .map((n, i) => {
                  return (
                    <Placeholder
                      key={`campaigns-table-skeleton-${i}`}
                      className="w-100 my-1"
                      style={{ height: '33px' }}
                      size="lg"
                    />
                  );
                })}
            {!campaignsLoading && (
              <AdvanceTableWrapper
                columns={columns}
                data={campaigns || []}
                sortable
                pagination
                perPage={10}
                rowCount={campaigns?.length || 0}
                manualSortBy
              >
                <AdvanceTable
                  table
                  headerClassName="bg-light text-800 align-middle"
                  rowClassName="btn-reveal-trigger align-top"
                  tableProps={{
                    bordered: true,
                    striped: true,
                    hover: true
                  }}
                />
              </AdvanceTableWrapper>
            )}
            {!campaignsLoading && !campaigns?.length && (
              <p className="w-100 text-center">
                {t('common:placeholder.noDataAvailable')}
              </p>
            )}
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination
              table
              pageCount={pagingInfo?.totalPages || 0}
              pageIndex={pagingInfo?.currentPage || 1}
              gotoPage={page => {
                setFilters({ ...filters, pageNumber: page });
              }}
              isRtl={isRtl}
            />
          </Card.Footer>
        </Card>
      </Col>
      <Col xxl={2} xl={3}>
        {breakpoints.down('xl') ? (
          <Offcanvas
            show={show}
            onHide={handleClose}
            placement="end"
            className="dark__bg-card-dark"
          >
            <Offcanvas.Header closeButton className="bg-light">
              <h6 className="fs-0 mb-0 fw-semi-bold">
                {t('common:labels.filters')}
              </h6>
            </Offcanvas.Header>
            <CampaignsListFilter
              setShow={setShow}
              oldFilters={filters}
              onSubmit={newFilters =>
                handleFilterChange({
                  ...newFilters
                })
              }
            />
          </Offcanvas>
        ) : (
          <CampaignsListFilter
            setShow={setShow}
            oldFilters={filters}
            onSubmit={newFilters =>
              handleFilterChange({
                ...newFilters
              })
            }
          />
        )}
      </Col>
      {!!confirmModalProps && (
        <ConfirmModal {...confirmModalProps} loading={requestLoading} />
      )}
    </Row>
  );
};

//test comment for pipelines

export default CampaignsList;
