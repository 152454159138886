import React, { useContext, useState } from 'react';
import { Button, Card, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import SelectComponent from 'components/common/SelectComponent';
import AppContext, { DataContext } from 'context/Context';
import { campaignStatuses } from 'helpers/utils';

const CampaignsListFilter = ({ oldFilters, onSubmit, setShow }) => {
  const { countries, cities, setSelectedCountry } = useContext(DataContext);
  const [filters, setFilters] = useState({ ...oldFilters });
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const {
    config: { isRTL }
  } = useContext(AppContext);

  const handleFilter = (name, value) => {
    //console.log('handleFilter', { name, value });
    setFilters({ ...(filters || {}), [name]: value });
  };

  const statuses = campaignStatuses.map(state => {
    return {
      label: t(`campaign:state.${state.name}`),
      value: state.name
    };
  });

  return (
    <Card className="shadow-none shadow-show-xl scrollbar overflow-visible">
      <Card.Header className="bg-light d-none d-xl-flex align-items-center justify-content-between w-100">
        <h6 className="mb-0">{t('common:labels.filters')}</h6>
        <OverlayTrigger
          placement={isRTL ? 'right' : 'left'}
          overlay={
            <Tooltip id="user-filter-rest-tooltip">
              {t('common:tooltips.resetFilters')}
            </Tooltip>
          }
        >
          <span>
            <Button
              size="sm"
              variant="light"
              onClick={() => {
                setFilters(null);
                onSubmit(null);
              }}
            >
              <FontAwesomeIcon icon={faSyncAlt} size="sm" />
            </Button>
          </span>
        </OverlayTrigger>
      </Card.Header>
      <Card.Body>
        <Form>
          <div className="mb-2">
            <Form.Label className="mb-1 mt-2 fs--1">
              {t('campaign:labels.state')}
            </Form.Label>
            <SelectComponent
              options={statuses}
              onChange={option => handleFilter('status', option?.value || null)}
              value={statuses.find(c => c.value === filters?.status) || ''}
              placeholder={t('campaign:placeholders.statePlaceholder')}
              isClearable
            />
          </div>
          <div className="mb-2">
            <Form.Label className="mb-1 mt-2 fs--1">
              {t('campaign:labels.country')}
            </Form.Label>

            <SelectComponent
              options={countries}
              onChange={option => {
                handleFilter('country', option?.value || null);
                setSelectedCountry(option);
              }}
              value={countries.find(c => c.value === filters?.country) || ''}
              placeholder={t('campaign:placeholders.countryPlaceholder')}
              isClearable
            />
          </div>
          <div className="mb-2">
            <Form.Label className="mb-1 mt-2 fs--1">
              {t('campaign:labels.city')}
            </Form.Label>

            <SelectComponent
              options={cities}
              onChange={option => handleFilter('city', option?.value || null)}
              value={cities.find(c => c.value === filters?.city) || ''}
              placeholder={t('campaign:placeholders.cityPlaceholder')}
              isClearable
              isDisabled={!cities?.length}
            />
          </div>
        </Form>
      </Card.Body>
      <Card.Footer className="border-top border-200 py-x1">
        <Button
          varient="primary"
          className="w-100"
          onClick={() => {
            onSubmit({ ...filters });
            setShow(false);
          }}
        >
          {t('common:button.filter')}
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default CampaignsListFilter;
