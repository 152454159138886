import React, { useEffect, useState } from 'react';
import { CampaignContext, DataContext } from 'context/Context';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import CampaignService from 'http/CampaignService';
import { getErrorMessage } from 'http/utils';
import { toast } from 'react-toastify';
import LoaderWithMessage from 'components/common/LoaderWithMessage';
import LoadingSpinner from 'components/common/LoadingSpinner';
import moment from 'moment';
import ErrorPlaceholder from 'components/common/ErrorPlaceholder';
import { useContext } from 'react';
import { sumProperty } from 'helpers/utils';

const initCampaign = {};

const CampaignProvider = ({ children }) => {
  const { id } = useParams();
  const { setSelectedCountry } = useContext(DataContext);
  const [searchParams] = useSearchParams();
  const packageId = searchParams?.get('packageId');
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const [campaign, setCampaign] = useState(null);
  const [campaignPackage, setCampaignPackage] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [campaignErrors, setCampaignErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    isLoading: campaignLoading,
    error: campaignError,
    data: campaignData,
    refetch: fetchCampaign
  } = useQuery(['GetCampaign', { id }], () => CampaignService.get(id), {
    enabled: false, // Prevents automatic refetching
    retry: false,
    manual: true,
    refetchOnWindowFocus: false
  });

  const {
    isLoading: packageLoading,
    error: packageError,
    data: packageData,
    refetch: fetchPackage
  } = useQuery(
    ['GetPackage', { id: packageId }],
    () => CampaignService.getPackage(packageId),
    {
      enabled: false, // Prevents automatic refetching
      retry: false,
      manual: true,
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    if (packageId) {
      fetchPackage(packageId);
    }

    if (!!id) {
      fetchCampaign(id);
    } else {
      setCampaign({ ...initCampaign, packageId: packageId ?? null });
      setIsEdit(true);
    }
  }, [id]);

  useEffect(() => {
    if (campaignData && !campaignLoading && !campaignError) {
      setSelectedCountry(campaignData?.target?.locations?.[0]?.country);
      setCampaign(campaignData);
    }
    if (campaignError) {
      toast.error(getErrorMessage(t, campaignError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [campaignData, campaignError, campaignLoading]);

  useEffect(() => {
    if (packageData && !packageLoading && !packageError) {
      setCampaignPackage(packageData);
    }
    if (packageError) {
      toast.error(getErrorMessage(t, packageError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [packageData, packageError, packageLoading]);

  const validateCampaignForSubmit = data => {
    let errors = {};

    if (!data?.title?.ar) {
      errors['titleAr'] = t('common:validation.fieldIsRequired');
    } else {
      if (data?.title?.ar?.length > 65) {
        errors['titleAr'] = t('common:validation.maxAllowedCharacters', {
          count: '65'
        });
      } else {
        errors['titleAr'] = null;
      }
    }

    if (!data?.title?.en) {
      errors['titleEn'] = t('common:validation.fieldIsRequired');
    } else {
      if (data?.title?.en?.length > 65) {
        errors['titleEn'] = t('common:validation.maxAllowedCharacters', {
          count: '65'
        });
      } else {
        errors['titleEn'] = null;
      }
    }

    if (!data?.description?.ar) {
      errors['descriptionAr'] = t('common:validation.fieldIsRequired');
    } else {
      if (data?.description?.ar?.length > 250) {
        errors['descriptionAr'] = t('common:validation.maxAllowedCharacters', {
          count: '250'
        });
      } else {
        errors['descriptionAr'] = null;
      }
    }

    if (!data?.description?.en) {
      errors['descriptionEn'] = t('common:validation.fieldIsRequired');
    } else {
      if (data?.description?.en?.length > 250) {
        errors['descriptionEn'] = t('common:validation.maxAllowedCharacters', {
          count: '250'
        });
      } else {
        errors['descriptionEn'] = null;
      }
    }

    if (!data?.startDate) {
      errors['startDate'] = t('common:validation.fieldIsRequired');
    } else {
      if (!!data?.startDate) {
        if (moment(data?.startDate).diff(moment(), 'days') < 7) {
          errors['startDate'] = t('campaign:errors.startDateIsNear');
        }
      } else {
        errors['startDate'] = null;
      }
    }

    if (!data?.endDate) {
      errors['endDate'] = t('common:validation.fieldIsRequired');
    } else {
      errors['endDate'] = null;
    }

    if (!!data?.startDate && !!data?.endDate) {
      if (moment(data?.startDate).isAfter(data?.endDate)) {
        errors['startDate'] = t('campaign:errors.startIsAfterEnd');
        errors['endDate'] = t('campaign:errors.endIsBeforeStart');
      }
    }

    if (!data?.businessType) {
      errors['businessType'] = t('common:validation.fieldIsRequired');
    } else {
      errors['businessType'] = null;
    }

    if (!data?.organizations?.length) {
      errors['organizations'] = t('common:validation.fieldIsRequired');
    } else {
      errors['organizations'] = null;
    }
    if (!data?.products?.length) {
      errors['products'] = t('common:validation.fieldIsRequired');
    } else {
      data?.products?.map((p, i) => {
        if (!p.label || (!p.source && !p.file)) {
          errors[`productsItems-${i}`] = t('common:validation.fieldIsRequired');
        } else {
          errors[`productsItems-${i}`] = null;
        }
      });
    }
    if (!data?.target?.age?.from) {
      errors['targetAgeFrom'] = t('common:validation.fieldIsRequired');
    }
    if (!data?.target?.age?.to) {
      errors['targetAgeTo'] = t('common:validation.fieldIsRequired');
    }
    if (!data?.target?.locations?.[0]?.country?.id) {
      errors['targetCountry'] = t('common:validation.fieldIsRequired');
    }
    if (!data?.target?.locations?.[0]?.city?.id) {
      errors['targetCity'] = t('common:validation.fieldIsRequired');
    }
    if (!!data?.target?.age?.from && !!data?.target?.age?.to) {
      if (data?.target?.age?.from > data?.target?.age?.to) {
        errors['targetAgeFrom'] = t('campaign:errors.targetAgeFromAfterTo');
        errors['targetAgeTo'] = t('campaign:errors.targetAgeToAfterFrom');
      }
    }
    if (!data?.target?.gender) {
      errors['targetGender'] = t('common:validation.fieldIsRequired');
    }

    if (!data?.prizes || !data?.prizes?.[0]?.groups?.length) {
      errors['prizes'] = t('common:validation.fieldIsRequired');
    }

    if (!!data?.prizes?.[0]?.groups?.length) {
      data?.prizes?.[0]?.groups?.map((g, i) => {
        if (!g.percentage) {
          errors[`group${i}-percentage`] = t(
            'common:validation.fieldIsRequired'
          );
        }
        if (!g.valuePercentage) {
          errors[`group${i}-valuePercentage`] = t(
            'common:validation.fieldIsRequired'
          );
        }
      });
      const percentageSum = sumProperty(
        data?.prizes?.[0]?.groups,
        'percentage'
      );
      const valueSum = sumProperty(
        data?.prizes?.[0]?.groups,
        'valuePercentage'
      );
      if (percentageSum !== 100) {
        errors['percentageSum'] = t('campaign:errors.sumOfPercentage');
      }
      if (valueSum !== 100) {
        errors['valueSum'] = t('campaign:errors.sumOfValuePercentage');
      }
    }

    //handled in products card
    //else {
    //  errors['products'] = null;
    //}

    console.log('Validate Campaign for submit...', { errors });
    setCampaignErrors(errors);

    if (Object.values(errors).some(s => !!s)) {
      return true;
    }
    return false;
  };

  const validateCampaignForSave = data => {
    let errors = {};
    if (!data?.title?.ar) {
      errors['titleAr'] = t('common:validation.fieldIsRequired');
    } else {
      if (data?.title?.ar?.length > 65) {
        errors['titleAr'] = t('common:validation.maxAllowedCharacters', {
          count: '65'
        });
      } else {
        errors['titleAr'] = null;
      }
    }

    if (!data?.title?.en) {
      errors['titleEn'] = t('common:validation.fieldIsRequired');
    } else {
      if (data?.title?.en?.length > 65) {
        errors['titleEn'] = t('common:validation.maxAllowedCharacters', {
          count: '65'
        });
      } else {
        errors['titleEn'] = null;
      }
    }

    if (!data?.description?.ar) {
      errors['descriptionAr'] = t('common:validation.fieldIsRequired');
    } else {
      if (data?.description?.ar?.length > 250) {
        errors['descriptionAr'] = t('common:validation.maxAllowedCharacters', {
          count: '250'
        });
      } else {
        errors['descriptionAr'] = null;
      }
    }

    if (!data?.description?.en) {
      errors['descriptionEn'] = t('common:validation.fieldIsRequired');
    } else {
      if (data?.description?.en?.length > 250) {
        errors['descriptionEn'] = t('common:validation.maxAllowedCharacters', {
          count: '250'
        });
      } else {
        errors['descriptionEn'] = null;
      }
    }

    if (!data?.startDate) {
      errors['startDate'] = t('common:validation.fieldIsRequired');
    } else {
      if (!!data?.startDate) {
        if (moment(data?.startDate).diff(moment(), 'days') < 7) {
          errors['startDate'] = t('campaign:errors.startDateIsNear');
        }
      } else {
        errors['startDate'] = null;
      }
    }

    if (!data?.endDate) {
      errors['endDate'] = t('common:validation.fieldIsRequired');
    } else {
      errors['endDate'] = null;
    }

    if (!!data?.startDate && !!data?.endDate) {
      if (moment(data?.startDate).isAfter(data?.endDate)) {
        errors['startDate'] = t('campaign:errors.startIsAfterEnd');
        errors['endDate'] = t('campaign:errors.endIsBeforeStart');
      }
    }

    if (!data?.businessType) {
      errors['businessType'] = t('common:validation.fieldIsRequired');
    } else {
      errors['businessType'] = null;
    }

    if (!!data?.products?.length) {
      data?.products?.map((p, i) => {
        if (!p.label || (!p.source && !p.file)) {
          errors[`productsItems-${i}`] = t('common:validation.fieldIsRequired');
        } else {
          errors[`productsItems-${i}`] = null;
        }
      });
    }

    console.log('Validate Campaign for save...', { errors });
    setCampaignErrors(errors);

    if (Object.values(errors).some(s => !!s)) {
      return true;
    }
    return false;
  };

  const campaignProviderValue = {
    campaign,
    setCampaign,
    setIsEdit,
    isEdit,
    campaignErrors,
    setCampaignErrors,
    loading,
    setLoading,
    validateCampaignForSubmit,
    validateCampaignForSave,
    campaignPackage,
    packageLoading,
    fetchCampaign
  };

  if (packageLoading) {
    return <LoaderWithMessage message={t('campaign:loading.loadingPackage')} />;
  }

  if (campaignLoading) {
    return (
      <LoaderWithMessage message={t('campaign:loading.loadingCampaign')} />
    );
  }

  if (campaignError) {
    return (
      <ErrorPlaceholder
        message={t('campaign:loading.errorLoadingCampaigns')}
        className="mt-3"
      />
    );
  }

  return (
    <CampaignContext.Provider value={campaignProviderValue}>
      <LoadingSpinner loading={loading} id="campaign-global-spinner" />
      {children}
    </CampaignContext.Provider>
  );
};

export default CampaignProvider;
