import Flex from 'components/common/Flex';
import { CampaignContext } from 'context/Context';
import React, { useContext, useState, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { getErrorMessage, toFileUrl } from 'http/utils';
import IconButton from 'components/common/IconButton';
import { faCloudDownloadAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import CampaignService from 'http/CampaignService';
import { toast } from 'react-toastify';
import ConfirmModal from 'components/common/ConfirmModal';
import {
  generateRandomPassword,
  getFileExtension,
  getFileExtensionFromUrl
} from 'helpers/utils';
import SpinnerLightBoxGallery from 'components/common/SpinnerLightBoxGallery';

const CampaignProductsCard = () => {
  const { campaign, setCampaign, campaignErrors, setCampaignErrors, isEdit } =
    useContext(CampaignContext);
  const { t } = useTranslation();
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    //maxSize: 1048576,
    onDrop: (acceptedFiles, fileRejections) =>
      handleUpload(acceptedFiles, fileRejections)
  });

  useEffect(() => {
    if (campaign?.products?.length) {
      handlePreviewFiles(campaign?.products);
    } else {
      setUploadedFiles([]);
    }
  }, [campaign]);

  const handlePreviewFiles = async data => {
    let filesData = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      if (!element?.file) {
        filesData.push(element);
        continue;
      }

      const imageURL = await createObjectURL(element?.file);
      filesData.push({
        source: imageURL,
        ...element
      });
    }

    setUploadedFiles(filesData);
  };

  const handleUpload = async (acceptedFiles, fileRejections) => {
    let errors = {};
    if (!!fileRejections?.length) {
      fileRejections.forEach(file => {
        file.errors.forEach(err => {
          if (err.code === 'file-too-large') {
            errors['products'] = t('campaign:errors.fileTooLarge');
          }

          if (err.code === 'file-invalid-type') {
            errors['products'] = t('campaign:errors.invalidFileType');
          }
        });
      });
    } else {
      errors['products'] = null;
    }

    setCampaignErrors({ ...campaignErrors, ...errors });
    setCampaign({
      ...campaign,
      products: [
        ...(campaign.products || []),
        ...acceptedFiles.map(f => ({
          file: f,
          label: f.name.replace(/\.[^/.]+$/, ''),
          width: 1,
          height: 1,
          temp: true,
          id: generateRandomPassword(8)
        }))
      ]
    });
  };

  const createObjectURL = file => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = e => {
        resolve(e.target.result);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleClearImages = () => {
    setCampaign({ ...campaign, products: null });
  };

  return (
    <Card className="mt-3">
      {/* {!!loading && (
        <div
          className="w-100 h-100 d-flex align-items-center justify-content-center"
          style={{ position: 'absolute' }}
        >
          <div
            className="w-100 h-100"
            style={{
              position: 'absolute',
              background: 'black',
              opacity: 0.5,
              zIndex: 1
            }}
          ></div>
          <div style={{ zIndex: 2 }}>
            <Spinner className="mx-2" variant="light" size="sm" />{' '}
            <span className="text-white">
              {`   ${t('common:loading.loading')}`}
            </span>
          </div>
        </div>
      )} */}
      <Card.Header>
        {`${t('campaign:labels.productsTitle')}  `}
        <small>
          {`(${t('campaign:message.upToAllowed', {
            count: `10 ${t('campaign:labels.productsTitle')}`
          })})`}
        </small>
      </Card.Header>
      <Card.Body>
        {/* {!!uploadedFiles?.length && <ImagesGallery images={uploadedFiles} />} */}
        {!!uploadedFiles?.length && (
          <SpinnerLightBoxGallery
            images={
              uploadedFiles?.map(f =>
                !!f.temp ? f.source : toFileUrl(f.source)
              ) || []
            }
          >
            {setImgIndex =>
              uploadedFiles.map((item, i) => {
                return (
                  <ProductItemPreview
                    key={`campaign-product-item-${i}`}
                    product={item}
                    index={i}
                    handlePreview={index => setImgIndex(index)}
                  />
                );
              })
            }
          </SpinnerLightBoxGallery>
        )}
        {isEdit && uploadedFiles?.length < 10 && (
          <div
            {...getRootProps({
              className: 'dropzone-area py-6 mt-3',
              style: {
                border: !!campaignErrors?.products ? '1px solid red' : '',
                background: !isEdit ? '#edf2f9' : ''
              }
            })}
          >
            <input {...getInputProps()} disabled={!isEdit} />
            <Flex justifyContent="center">
              <img src={cloudUpload} alt="" width={25} className="me-2" />
              <p className="fs-0 mb-0 text-700">
                {t('common:placeholder.uploadFileByDragDrop')}
              </p>
            </Flex>
          </div>
        )}
        {!!campaignErrors?.products && (
          <p className="text-danger">{campaignErrors?.products}</p>
        )}
      </Card.Body>
    </Card>
  );
};

const ProductItemPreview = ({ product, index, handlePreview }) => {
  const { campaign, setCampaign, campaignErrors, setCampaignErrors, isEdit } =
    useContext(CampaignContext);
  const { t } = useTranslation();
  const [hovered, setHovered] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleClickDeleteProduct = () => {
    if (!!product.temp) {
      console.log('handleClickDeleteProduct temp', {
        product,
        products: campaign.products
      });
      setCampaign({
        ...campaign,
        products: campaign.products.filter(p => p.id !== product.id)
      });
      return;
    }
    setConfirmModalProps({
      isOpen: true,
      onClose: () => setConfirmModalProps(null),
      onConfirm: handleDeleteProduct,
      message: t('campaign:message.deleteProdcutConfirm'),
      header: t(`campaign:message.deleteProdcutConfirmTitle`),
      actionButtonColor: 'danger',
      actionButtonText: t(`common:button.delete`)
    });
  };

  const handleDeleteProduct = () => {
    setDeleteLoading(true);
    CampaignService.deleteProduct(campaign.id, product)
      .then(response => {
        setCampaign({
          ...campaign,
          products: campaign.products.filter(p => p.source !== product.source)
        });

        toast.success(t('campaign:message.productDeleteSuccess'));
        setConfirmModalProps(null);
        setDeleteLoading(false);
      })
      .catch(error => {
        setDeleteLoading(false);
        toast.error(getErrorMessage(t, error));
      });
  };

  const handleDownloadProduct = () => {
    fetch(product.source)
      .then(response => response.blob())
      .then(blob => {
        // Creating a new anchor element
        const link = document.createElement('a');

        // Creating an Object URL for the Blob and setting it as the href attribute
        link.href = URL.createObjectURL(blob);

        // Setting the download attribute with the desired filename
        link.download = `${product.label}.${getFileExtensionFromUrl(
          product.source
        )}`;

        // Appending the anchor to the body
        document.body.appendChild(link);

        // Programmatically clicking the anchor to trigger the download
        link.click();

        // Removing the anchor from the body after download starts
        document.body.removeChild(link);

        // Revoking the Object URL to free up memory
        URL.revokeObjectURL(link.href);
      })
      .catch(error => console.error('File download error:', error));
  };

  const handleChangeProductLabel = e => {
    if (!isEdit) {
      return;
    }
    if (e?.target?.value?.length > 60) {
      return;
    }

    let data = [...campaign.products];
    for (let i = 0; i < data.length; i++) {
      if (index === i) {
        data[index].label = e.target.value;
      }
    }
    setCampaign({ ...campaign, products: data });
  };

  if (!product.source) {
    // console.log('product', product);
    return '';
  }

  return (
    <Row
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className="mt-2"
    >
      <Col
        xs={12}
        md={2}
        className="d-flex align-items-center justify-content-center cursor-pointer"
        onClick={() => handlePreview(index)}
      >
        <img
          src={!product.temp ? toFileUrl(product.source) : product.source}
          title={product.label || 'Product Image'}
          width={'100%'}
          height={'100%'}
        />
      </Col>
      <Col xs={12} md={8}>
        {!isEdit && (
          <h5 className="text-start">{product.label || 'Product Image'}</h5>
        )}
        {!!isEdit && (
          <Form.Group
            as={Col}
            xs="12"
            className="mb-3"
            controlId="campaign:form.label"
          >
            <Form.Label>{t('campaign:form.label')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('campaign:form.label')}
              onChange={handleChangeProductLabel}
              value={product.label || ''}
              isInvalid={!!campaignErrors?.[`productsItems-${index}`]}
              disabled={!isEdit}
              maxLength={65}
            />
            <Form.Text className="text-danger">
              {campaignErrors?.[`productsItems-${index}`]}
            </Form.Text>
          </Form.Group>
        )}
      </Col>
      <Col xs={12} md={2}>
        {hovered && (
          <>
            {isEdit && (
              <OverlayTrigger
                overlay={
                  <Tooltip
                    style={{ position: 'fixed' }}
                    id={`overlay-trigger-delete-${product.source}`}
                  >
                    {t(`common:button.delete`)}
                  </Tooltip>
                }
              >
                <span className="nav-item">
                  <IconButton
                    icon={faTrash}
                    variant="light"
                    onClick={handleClickDeleteProduct}
                    className="mx-1"
                  />
                </span>
              </OverlayTrigger>
            )}
            <OverlayTrigger
              overlay={
                <Tooltip
                  style={{ position: 'fixed' }}
                  id={`overlay-trigger-download-${product.source}`}
                >
                  {t(`common:button.download`)}
                </Tooltip>
              }
            >
              <span className="nav-item">
                <IconButton
                  icon={faCloudDownloadAlt}
                  variant="light"
                  onClick={handleDownloadProduct}
                  className="mx-1"
                />
              </span>
            </OverlayTrigger>
          </>
        )}
      </Col>
      {!!confirmModalProps && (
        <ConfirmModal {...confirmModalProps} loading={deleteLoading} />
      )}
    </Row>
  );
};

export default CampaignProductsCard;
