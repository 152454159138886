import React, { useContext } from 'react';
import AppContext from 'context/Context';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { themeColors } from 'helpers/utils';

const LanguageMenu = () => {
  const {
    config: { languages, preferredLanguage },
    setConfig
  } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        className="p-0 ps-2 nav-link border-0 position-relative"
        style={{ background: 'transparent' }}
      >
        <FontAwesomeIcon icon={faGlobe} className="me-1" size="2x" />
        <span
          style={{
            position: 'absolute',
            bottom: '0',
            left: '0',
            borderRadius: '50%',
            background: themeColors.primary,
            color: themeColors.light,
            padding: '2px',
            fontSize: '0.60rem'
          }}
          className="d-flex align-items-center justify-content-center"
        >
          {preferredLanguage?.toUpperCase()}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {languages.map(lang => {
            if (lang.tag === preferredLanguage) {
              return '';
            }
            return (
              <Dropdown.Item
                href="#!"
                key={lang.tag}
                onClick={() => {
                  window.location.reload();
                  setConfig('preferredLanguage', lang.tag);
                  if (lang.rtl) {
                    setConfig('isRTL', true);
                  } else {
                    setConfig('isRTL', false);
                  }
                }}
                title={t('common:language.' + lang.tag + '.tooltip')}
                id={`change_lng_btn_${lang.tag}`}
              >
                {t('common:language.' + lang.tag + '.name')}
              </Dropdown.Item>
            );
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageMenu;
