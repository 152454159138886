import SelectComponent from 'components/common/SelectComponent';
import { CampaignContext, DataContext } from 'context/Context';
import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const CampaignTarget = () => {
  const { t } = useTranslation();
  const { countries, selectedCountry, setSelectedCountry, cities } =
    useContext(DataContext);
  const { campaign, setCampaign, campaignErrors, isEdit } =
    useContext(CampaignContext);

  const handleChange = (name, value) => {
    console.log(name, value);
    if (name === 'targetAgeFrom') {
      setCampaign({
        ...campaign,
        target: {
          ...campaign?.target,
          age: {
            ...campaign?.target?.age,
            from: value
          }
        }
      });
    }
    if (name === 'targetAgeTo') {
      setCampaign({
        ...campaign,
        target: {
          ...campaign?.target,
          age: {
            ...campaign?.target?.age,
            to: value
          }
        }
      });
    }
    if (name === 'country') {
      setSelectedCountry(value);
      let locations = [
        {
          country: value
        }
      ];
      setCampaign({
        ...campaign,
        target: {
          ...campaign?.target,
          locations
        }
      });
    }
    if (name === 'city') {
      let locations = [
        {
          country: campaign?.target?.locations?.[0]?.country,
          city: value,
          latitude: value?.latitude,
          longitude: value?.longitude
        }
      ];
      setCampaign({
        ...campaign,
        target: {
          ...campaign?.target,
          locations
        }
      });
    }
    if (name === 'targetGender') {
      setCampaign({
        ...campaign,
        target: {
          ...campaign?.target,
          gender: value
        }
      });
    }
  };

  const targetAgeOptions = () => {
    return [
      ...Array(51)
        .fill(0)
        .map((n, i) => {
          return {
            label: i + 15,
            value: i + 15
          };
        }),
      { label: '+65', value: 66 }
    ];
  };

  return (
    <Card className="mt-3">
      <Card.Header>{t('campaign:labels.targetTitle')}</Card.Header>
      <Card.Body>
        <Row>
          <Col md={6}>
            <Form.Group
              as={Col}
              xs="12"
              className="mb-3"
              controlId="campaign.targetAgeFrom"
            >
              <Form.Label>{t('campaign:form.targetAgeFrom')}</Form.Label>
              <SelectComponent
                options={targetAgeOptions()}
                placeholder={t('campaign:form.targetAgeFrom')}
                classNamePrefix="react-select"
                value={
                  targetAgeOptions()?.find(
                    o => campaign?.target?.age?.from === o.value
                  ) || null
                }
                onChange={option =>
                  handleChange('targetAgeFrom', option?.value || null)
                }
                errorText={campaignErrors?.targetAgeFrom || ''}
                invalid={!!campaignErrors?.targetAgeFrom}
                isDisabled={!isEdit}
              />

              <Form.Text className="text-danger">
                {campaignErrors?.targetAgeFrom}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group
              as={Col}
              xs="12"
              className="mb-3"
              controlId="campaign.targetAgeTo"
            >
              <Form.Label>{t('campaign:form.targetAgeTo')}</Form.Label>
              <SelectComponent
                options={targetAgeOptions()}
                placeholder={t('campaign:form.targetAgeTo')}
                classNamePrefix="react-select"
                value={
                  targetAgeOptions()?.find(
                    o => campaign?.target?.age?.to === o.value
                  ) || null
                }
                onChange={option =>
                  handleChange('targetAgeTo', option?.value || null)
                }
                errorText={campaignErrors?.targetAgeTo || ''}
                invalid={!!campaignErrors?.targetAgeTo}
                isDisabled={!isEdit}
              />
              <Form.Text className="text-danger">
                {campaignErrors?.targetAgeTo}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group
              as={Col}
              xs="12"
              className="mb-3"
              controlId="campaign.targetGender"
            >
              <Form.Label>{t('campaign:form.targetGender')}</Form.Label>
              <Form.Select
                type="text"
                placeholder={t('campaign:form.targetGender')}
                value={campaign?.target?.gender || ''}
                onChange={e => handleChange('targetGender', e.target.value)}
                isInvalid={!!campaignErrors?.targetAgeTo}
                disabled={!isEdit}
              >
                <option value="">
                  {t('campaign:placeholders.selectGender')}
                </option>
                <option value="MALE">{t('campaign:gender.male')}</option>
                <option value="FEMALE">{t('campaign:gender.female')}</option>
                <option value="BOTH">{t('campaign:gender.both')}</option>
              </Form.Select>
              <Form.Text className="text-danger">
                {campaignErrors?.targetAgeTo}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
          <Col
            xs="auto"
            className="navbar-vertical-label navbar-vertical-label"
          >
            {t('campaign:labels.targetLocations')}
          </Col>
          <Col className="ps-0">
            <hr className="mb-0 navbar-vertical-divider"></hr>
          </Col>
        </Row>

        <Row>
          <Form.Group
            as={Col}
            xs="12"
            className="mb-3"
            controlId="campaign.country"
          >
            <Form.Label>{t('campaign:form.targetCountry')}</Form.Label>
            <SelectComponent
              options={countries}
              placeholder={t('campaign:form.targetCountry')}
              classNamePrefix="react-select"
              value={
                countries?.find(
                  o => campaign?.target?.locations?.[0]?.country?.id === o.id
                ) || null
              }
              onChange={value => handleChange('country', value)}
              errorText={campaignErrors?.targetCountry || ''}
              invalid={!!campaignErrors?.targetCountry}
              isDisabled={!isEdit}
            />
            <Form.Text className="text-danger">
              {campaignErrors?.targetCountry}
            </Form.Text>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group
            as={Col}
            xs="12"
            className="mb-3"
            controlId="campaign.city"
          >
            <Form.Label>{t('campaign:form.targetCity')}</Form.Label>
            <SelectComponent
              options={cities}
              placeholder={t('campaign:form.targetCity')}
              classNamePrefix="react-select"
              value={
                cities?.find(
                  o => campaign?.target?.locations?.[0]?.city?.id === o.id
                ) || null
              }
              onChange={value => handleChange('city', value)}
              errorText={campaignErrors?.targetCity || ''}
              invalid={!!campaignErrors?.targetCity}
              isDisabled={!isEdit || !selectedCountry}
            />
            <Form.Text className="text-danger">
              {campaignErrors?.targetCity}
            </Form.Text>
          </Form.Group>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CampaignTarget;
