import React, { useEffect, useState } from 'react';
import { Card, Tab, Form } from 'react-bootstrap';
import UsersChart from './UsersChart';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import StatisticsService from 'http/StatisticsService';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import { useTranslation } from 'react-i18next';
import { constructDate } from 'helpers/utils';
import SpinnerCardHeader from 'components/common/SpinnerCardHeader';

const initFilter = {
  type: 'daily',
  fromDate: constructDate({ date: new Date(), addDays: -14, iso: true }),
  toDate: new Date().toISOString()
};

const Users = ({ className }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState(initFilter);
  const [chartDays, setChartDays] = useState(7);

  const {
    //refetch: fetchUsersStats,
    isLoading: usersStatsLoading,
    data: usersStatsData,
    error: usersStatsError
  } = useQuery(
    ['UsersStats', filters],
    () => StatisticsService.usersOverPeriod(filters),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: true
    }
  );

  useEffect(() => {
    if (usersStatsData && !usersStatsLoading && !usersStatsError) {
      if (usersStatsData?.errors?.length) {
        toast.error(getErrorMessage(t, usersStatsData?.errors), {
          theme: 'colored',
          autoClose: false
        });
      }
      if (!!usersStatsData?.payload?.length) {
        let arrayValues = usersStatsData?.payload.map(d => d.number);

        var half_length = Math.ceil(arrayValues.length / 2);

        var leftSide = arrayValues.slice(0, half_length);
        var rightSide = arrayValues.slice(half_length, arrayValues.length);
        setUsers([leftSide, rightSide]);
      }
    }

    if (usersStatsError) {
      toast.error(getErrorMessage(t, usersStatsError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [usersStatsLoading, usersStatsData, usersStatsError]);

  useEffect(() => {
    let newFilters = {
      ...filters
    };
    switch (chartDays) {
      case 7:
        newFilters = {
          toDate: new Date().toISOString(),
          type: 'daily',
          fromDate: constructDate({ date: new Date(), addDays: -14, iso: true })
        };
        break;
      case 30:
        newFilters = {
          toDate: new Date().toISOString(),
          type: 'daily',
          fromDate: constructDate({
            date: new Date(),
            addMonths: -2,
            iso: true
          })
        };
        break;
      case 365:
        newFilters = {
          toDate: new Date().toISOString(),
          type: 'daily',
          fromDate: constructDate({ date: new Date(), addYears: -2, iso: true })
        };
        break;
      default:
        break;
    }
    //console.log('newFilters', newFilters);
    setFilters(newFilters);
  }, [chartDays]);

  return (
    <Card className={classNames(className, 'overflow-hidden')}>
      <Tab.Container id="audience-tab" defaultActiveKey="users">
        <SpinnerCardHeader
          title={t('dashboard:labels.totalUsers')}
          titleTag="h6"
          className="py-2"
          light
          endEl={
            <Form.Select
              size="sm"
              className="me-2"
              onChange={e => setChartDays(Number(e.target.value))}
            >
              <option value={'7'}>{t('common:labels.last7Days')}</option>
              <option value={'30'}>{t('common:labels.lastMonth')}</option>
              <option value={'365'}>{t('common:labels.lastYear')}</option>
            </Form.Select>
          }
        />

        <Card.Body>
          <Tab.Content>
            <Tab.Pane unmountOnExit eventKey="users">
              <UsersChart
                data={users}
                days={chartDays}
                currentLanguage={currentLanguage}
                t={t}
              />
            </Tab.Pane>
          </Tab.Content>
        </Card.Body>
      </Tab.Container>

      {/* <Card.Footer className="bg-light py-2">
        <Row className="g-0 flex-between-center">
          <Col xs="auto">
            <Form.Select
              size="sm"
              className="me-2"
              onChange={e => setChartDays(Number(e.target.value))}
            >
              <option value={'7'}>{t('common:labels.last7Days')}</option>
              <option value={'30'}>{t('common:labels.lastMonth')}</option>
              <option value={'365'}>{t('common:labels.lastYear')}</option>
            </Form.Select>
          </Col>
        </Row>
      </Card.Footer> */}
    </Card>
  );
};

export default Users;
