import axios from 'axios';
import { getDeviceId, getItemFromStore } from 'helpers/utils';
import moment from 'moment';

const currentLocale =
  getItemFromStore('preferredLanguage', 'en') ||
  process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_AUTH_URL = `${process.env.REACT_APP_API_AUTH_URL}/api/${currentLocale}/${process.env.REACT_APP_API_VERSION}/identity`;

const http = axios.create();

let refreshRequest = null;
let tokenRefreshedCallbacks = [];

http.interceptors.request.use(
  async config => {
    // Check if a token is available and not expired
    let userAuthData = null;
    try {
      userAuthData = JSON.parse(localStorage.getItem('SPINNER_admin_user'));
    } catch {
      userAuthData = null;
    }
    const token = userAuthData?.token;
    const refreshToken = userAuthData?.refreshToken;
    const tokenExpiration = moment(userAuthData?.expiration);
    const rememberMe = userAuthData?.remember;
    //console.log('config', config);
    config.headers['Accept-Language'] = currentLocale;

    //Execlude auth apis
    if (config?.url.startsWith(BASE_AUTH_URL)) {
      return config;
    }

    if (token && tokenExpiration && Date.now() < tokenExpiration - 20000) {
      // If the token is still valid, add it to the authorization header
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    }
    if (!rememberMe) {
      localStorage.removeItem('SPINNER_admin_user');
      //window.location.href = '/login';
      return config;
    }
    // If the token is expired or doesn't exist, block the request and refresh the token
    if (!refreshRequest) {
      // If there is no refresh token request in progress, send one and store the promise

      const deviceId = getDeviceId();

      refreshRequest = axios.post(
        `${BASE_AUTH_URL}/refreshToken`,
        {
          refreshToken: refreshToken,
          accessToken: token
        },
        { headers: { deviceId: deviceId } }
      );
    }
    const response = await refreshRequest;
    //console.log('refreshRequest', response);
    if (response.status !== 200) {
      refreshRequest = null;
      return Promise.reject(500);
    }
    // Update the token and token expiration in localStorage
    userAuthData.token = response.data.accessToken;
    userAuthData.refreshToken = response.data.refreshToken;
    userAuthData.expiration = response.data.expiration;

    // Add the token to the authorization header and clear the refresh request
    config.headers.Authorization = `Bearer ${response.data.accessToken}`;
    refreshRequest = null;

    // Call any callbacks that were waiting for the token to be refreshed
    tokenRefreshedCallbacks.forEach(callback => callback());
    localStorage.setItem('SPINNER_admin_user', JSON.stringify(userAuthData));
    return config;
  },
  error => {
    // Handle request error
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    // Do something with the response data
    return response.data;
  },
  error => {
    return Promise.reject(error);
  }
);

export default http;
