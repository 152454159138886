import http from 'http/interceptor';
import { getItemFromStore } from 'helpers/utils';
const currentLocale =
  getItemFromStore('preferredLanguage', 'en') ||
  process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/${currentLocale}/${process.env.REACT_APP_API_VERSION}`;
const CREATE_ORGANIZATION_URL = `${BASE_API_URL}/organization/create`;
const LIST_ORGANIZATION_RL = `${BASE_API_URL}/organization/list`;
const DELETE_ORGANIZATION_URL = `${BASE_API_URL}/organization/delete`;
const UPDATE_ORGANIZATION_STATUS_URL = `${BASE_API_URL}/organization/status/update`;
const GET_ORGANIZATION_RL = `${BASE_API_URL}/organization/get`;

const OrganizationService = {
  list: payload => {
    const url = `${LIST_ORGANIZATION_RL}`;

    return http.post(url, payload, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  get: id => {
    const url = `${GET_ORGANIZATION_RL}?id=${id}`;

    return http.get(url);
  },
  create: data => {
    return http.post(
      CREATE_ORGANIZATION_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );
  },
  delete: id => {
    let data = new FormData();
    data.append('id', id);
    return http.post(
      DELETE_ORGANIZATION_URL,
      { id },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  updateStatus: data => {
    return http.post(
      UPDATE_ORGANIZATION_STATUS_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  }
};

export default OrganizationService;
