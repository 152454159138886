/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
//import { useAsyncDebounce } from 'react-table';
import debounce from 'lodash.debounce';

const AdvanceTableSearchBox = ({
  globalFilter,
  setGlobalFilter,
  placeholder = 'Search...',
  className
}) => {
  const [value, setValue] = useState(globalFilter || '');

  const debouncedHandleChange = debounce(value => {
    //console.log('debouncedHandleChange setting filters..', value);
    setGlobalFilter(value || undefined);
  }, 500);

  return (
    <InputGroup className={classNames(className, 'position-relative')}>
      <FormControl
        value={value || ''}
        onChange={({ target: { value } }) => {
          debouncedHandleChange(value);
          setValue(value);
        }}
        size="sm"
        id="search"
        placeholder={placeholder}
        type="search"
        className="shadow-none"
      />
      <Button
        size="sm"
        variant="outline-gray"
        className="border-300 hover-border-gray"
      >
        <FontAwesomeIcon icon="search" className="fs--1" />
      </Button>
    </InputGroup>
  );
};

export default AdvanceTableSearchBox;
