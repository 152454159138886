export const objectToQueryString = payload => {
  if (!payload || !Object.entries(payload)?.length) {
    return '';
  }
  let queryString = '';
  for (const [key, value] of Object.entries(payload)) {
    if (value !== undefined && value !== null) {
      queryString += `${key}=${encodeURIComponent(value)}&`;
    }
  }
  return queryString.slice(0, -1);
};

export const getErrorMessage = (t, error) => {
  if (!t || !error) {
    if (!error) {
      console.error('Unable to extract error message, Empty Response!', error);
    }
    return 'Unknown error has occurred, Please try again later.';
  }
  if (typeof error === 'string') {
    return error;
  }
  try {
    //console.log('Extracting error message from:::', error);
    let errorObject = {
      statusCode: '400',
      statusPhrase: 'Bad Request',
      errors: ['Unknown error has occurred, Please try again later.']
    };
    if (error?.response?.data) {
      errorObject = error?.response?.data;
    } else if (error?.data) {
      errorObject = error?.data;
    } else if (error) {
      errorObject = error;
    }

    const { statusCode, statusPhrase, errors } = errorObject;
    if (Array.isArray(errors)) {
      let errorsMesages = '';
      errors?.map(er => {
        errorsMesages += `${er}. \n`;
      });
      let message = '';
      if (statusCode === 500) {
        message = t
          ? `${t(
              'common:error.applicaitonError'
            )}: ${statusPhrase}-${statusCode}\n ${errorsMesages}`
          : `Application Error: ${statusCode}-${statusPhrase}\n ${errorsMesages}`;
      } else {
        message = ` ${errorsMesages}`;
      }
      return message;
    } else {
      if (!errors) {
        return t
          ? t('common:error.unknownError')
          : 'Unknown error has occurred, Please try again later.';
      }
      let errorsMesages = '';
      // eslint-disable-next-line
      Object.entries(errors)?.map(([key, er]) => {
        if (Array.isArray(er)) {
          er.map(err => {
            errorsMesages += `${err}. \n`;
          });
        } else {
          errorsMesages += `${er}. \n`;
        }
      });
      let message = '';
      if (statusCode === 500) {
        message = t
          ? `${t(
              'common:error.applicaitonError'
            )}: ${statusPhrase}-${statusCode}\n ${errorsMesages}`
          : `Application Error: ${statusCode}-${statusPhrase}\n ${errorsMesages}`;
      } else {
        message = ` ${errorsMesages}`;
      }
      return message;
    }
  } catch (error) {
    console.error('Unable to extract error message ', {
      tryError: error,
      error
    });
    return t
      ? t('common:error.unknownError')
      : 'Unknown error has occurred, Please try again later.';
  }
};

export const formatFileSize = (size, t) => {
  return `${((size || 0) / (1024 * 1024)).toFixed(2)} ${t('common:labels.mb')}`;
};

export const toFileUrl = path => {
  if (!path) {
    return '';
  }
  if (path?.startsWith('/')) {
    return `${process.env.REACT_APP_FILES_URL}${path}`;
  }
  return `${process.env.REACT_APP_FILES_URL}/${path}`;
};
