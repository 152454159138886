import React from 'react';
import { Form, Tab, Tabs } from 'react-bootstrap';
import TinymceEditor from './TinymceEditor';
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import AppContext from 'context/Context';

const MultiLanguageRTE = ({
  activeLanguage,
  t,
  containerClassName,
  placeholderEn,
  placeholderAr,
  handleEnChange,
  handleArChange,
  valueEn,
  valueAr,
  errorEn,
  errorEnMessage,
  errorAr,
  errorArMessage,
  arEditorProps,
  enEditorProps,
  enableFullscreen,
  disabled
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState(
    `MultiLanguageRTE-content-${activeLanguage}`
  );

  useEffect(() => {
    if (!!errorEn && !errorAr) {
      setActiveTab('MultiLanguageRTE-content-en');
    }
    if (!!errorAr && !errorEn) {
      setActiveTab('MultiLanguageRTE-content-ar');
    }
    if (!!errorAr && !!errorEn) {
      setActiveTab(`MultiLanguageRTE-content-en`);
    }
  }, [errorEn, errorAr]);

  return (
    <div className={`${containerClassName ?? ''} position-relative`}>
      <Tabs
        defaultActiveKey={`MultiLanguageRTE-content-${activeLanguage || 'en'}`}
        activeKey={activeTab}
        onSelect={active => setActiveTab(active)}
      >
        <Tab
          eventKey="MultiLanguageRTE-content-en"
          title={t('common:language.en.name')}
          className="border-bottom border-x "
          tabClassName={errorEn ? 'text-danger mb-0 border-danger' : 'mb-0'}
        >
          <div
            className={`tinymce-editor-container position-relative ${
              !!errorEn ? 'invalid' : ''
            }`}
          >
            {disabled && (
              <div
                className="h-100 w-100"
                style={{
                  position: 'absolute',
                  background: isDark ? '#232e3c' : '#edf2f9',
                  cursor: 'not-allowed',
                  zIndex: 1,
                  opacity: '0.4'
                }}
              ></div>
            )}
            <TinymceEditor
              height="13.438rem"
              name="MultiLanguageRTE-content"
              handleChange={handleEnChange}
              value={valueEn || ''}
              placeholder={placeholderEn}
              invalid={errorEn}
              disabled={disabled}
              {...enEditorProps}
              enableFullscreen={enableFullscreen}
              className={`border-0 ${enEditorProps?.className || ''}`}
            />
          </div>

          {!!errorEn && !!errorEnMessage && (
            <Form.Text className="text-danger mx-3">{errorEnMessage}</Form.Text>
          )}
        </Tab>
        <Tab
          eventKey="MultiLanguageRTE-content-ar"
          title={t('common:language.ar.name')}
          className="border-bottom border-x"
          tabClassName={errorAr ? 'text-danger mb-0 border-danger' : 'mb-0'}
        >
          <div
            className={`tinymce-editor-container position-relative ${
              !!errorAr ? 'invalid' : ''
            }`}
          >
            {disabled && (
              <div
                className="h-100 w-100"
                style={{
                  position: 'absolute',
                  background: isDark ? '#232e3c' : '#edf2f9',
                  cursor: 'not-allowed',
                  zIndex: 99999,
                  opacity: '0.4'
                }}
              ></div>
            )}
            <TinymceEditor
              height="13.438rem"
              name="MultiLanguageRTE-content"
              handleChange={handleArChange}
              value={valueAr || ''}
              placeholder={placeholderAr}
              invalid={!!errorAr}
              disabled={disabled}
              {...arEditorProps}
              className={`border-0 ${arEditorProps?.className || ''}`}
              enableFullscreen={enableFullscreen}
            />
          </div>
          {!!errorAr && !!errorArMessage && (
            <Form.Text className="text-danger mx-3">{errorArMessage}</Form.Text>
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default MultiLanguageRTE;
