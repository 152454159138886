import http from 'http/interceptor';
import { getItemFromStore } from 'helpers/utils';
const currentLocale =
  getItemFromStore('preferredLanguage', 'en') ||
  process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/${currentLocale}/${process.env.REACT_APP_API_VERSION}`;
const LIST_COUNTRIES_RL = `${BASE_API_URL}/core/countries/list`;
const LIST_CITIES_RL = `${BASE_API_URL}/core/cities/list`;

const CoreService = {
  listCountries: payload => {
    const url = `${LIST_COUNTRIES_RL}`;

    return http.post(url, payload, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  listCities: payload => {
    const url = `${LIST_CITIES_RL}`;

    return http.post(url, payload, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  }
};

export default CoreService;
