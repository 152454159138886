import {
  faPlus,
  faPlusCircle,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import SelectComponent from 'components/common/SelectComponent';
import { CampaignContext } from 'context/Context';
import { sumProperty } from 'helpers/utils';
import React, { Fragment, useContext } from 'react';
import { useEffect } from 'react';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const initPrize = {
  type: 'DISCOUNT',
  groups: [
    {
      type: 'PERCENTAGE',
      count: null,
      percentage: 0,
      valuePercentage: 0,
      value: null
    }
  ]
};

const CampaignPrizes = () => {
  const { t } = useTranslation();
  const { campaign, setCampaign, isEdit, campaignErrors, campaignPackage } =
    useContext(CampaignContext);

  useEffect(() => {
    if (!campaign?.prizes?.length) {
      setCampaign({ ...campaign, prizes: [initPrize] });
    }
  }, [campaign]);

  const handleChange = (index, name, value) => {
    if (!!value && isNaN(value)) {
      return;
    }

    let oldData = { ...campaign?.prizes[0] };

    oldData.groups[index][name] = value;

    setCampaign({ ...campaign, prizes: [oldData] });
  };

  const handleAddGroup = () => {
    setCampaign({
      ...campaign,
      prizes: [
        {
          ...campaign?.prizes?.[0],
          groups: [
            ...campaign?.prizes?.[0]?.groups,
            { ...initPrize.groups?.[0] }
          ]
        }
      ]
    });
  };

  const handleDeleteGroup = index => {
    let oldData = campaign?.prizes[0]?.groups;
    oldData.splice(index, 1);
    setCampaign({
      ...campaign,
      prizes: [{ ...campaign?.prizes?.[0], groups: oldData }]
    });
  };

  const prizeTypes = [
    { value: 'DISCOUNT', label: t('campaign:type.discount') },
    { value: 'PRIZE', label: t('campaign:type.prize') }
  ];

  return (
    <Card className="mt-3">
      <Card.Header>{t('campaign:labels.prizesTitle')}</Card.Header>
      <Card.Body>
        <Row>
          <Form.Group
            as={Col}
            xs="12"
            className="mb-3"
            controlId="userForm.campaignPrizeType"
          >
            <Form.Label>{t('campaign:labels.campaignPrizeType')}</Form.Label>
            <SelectComponent
              options={prizeTypes}
              placeholder={t('campaign:labels.campaignPrizeType')}
              classNamePrefix="react-select"
              value={
                prizeTypes?.filter(
                  o => o.value === campaign?.prizes?.[0]?.type
                ) || null
              }
              onChange={value => {
                return;
                //setCampaign({
                //    ...campaign,
                //    prize: value
                //  })
              }}
              errorText={campaignErrors?.prizes || ''}
              invalid={!!campaignErrors?.prizes}
              isDisabled={true}
            />
          </Form.Group>
        </Row>

        <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
          <Col
            xs="auto"
            className="navbar-vertical-label navbar-vertical-label"
          >
            {t(
              campaign?.prizes?.[0]?.type?.toLowerCase() === 'discount'
                ? 'campaign:labels.discountGroups'
                : 'campaign:labels.prizeGroups'
            )}
          </Col>
          <Col className="ps-0">
            <hr className="mb-0 navbar-vertical-divider"></hr>
          </Col>
        </Row>
        <Row className="align-items-center">
          {campaign?.prizes?.[0]?.groups?.map((group, index) => {
            return (
              <Fragment key={`campaign-prize-group-${index}`}>
                <Col md={4}>
                  <Form.Group
                    as={Col}
                    xs="12"
                    className="mb-3"
                    controlId="userForm.percentage"
                  >
                    <Form.Label>
                      {t('campaign:labels.percentageOfTokens')}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder={t('campaign:labels.percentageOfTokens')}
                        value={group?.percentage || ''}
                        onChange={e =>
                          handleChange(index, 'percentage', e.target.value)
                        }
                        disabled={!isEdit}
                        isInvalid={
                          !!campaignErrors?.[`group${index}-percentage`]
                        }
                      />
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                    {!!campaignErrors?.[`group${index}-percentage`] && (
                      <small className="text-danger">
                        {campaignErrors?.[`group${index}-percentage`]}
                      </small>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={10} md={4}>
                  <Form.Group
                    as={Col}
                    xs="12"
                    className="mb-3"
                    controlId="userForm.tokensCount"
                  >
                    <Form.Label>
                      {t('campaign:labels.discountValue')}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder={t('campaign:labels.discountValue')}
                        value={group?.valuePercentage || ''}
                        onChange={e =>
                          handleChange(index, 'valuePercentage', e.target.value)
                        }
                        disabled={!isEdit}
                        isInvalid={
                          !!campaignErrors?.[`group${index}-valuePercentage`]
                        }
                      />
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                    {!!campaignErrors?.[`group${index}-valuePercentage`] && (
                      <small className="text-danger">
                        {campaignErrors?.[`group${index}-valuePercentage`]}
                      </small>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={2}>
                  <small>
                    {`(${t('campaign:labels.numberOfTokens', {
                      number:
                        ((campaignPackage?.tokensCount || 0) *
                          (group?.percentage || 0)) /
                        100
                    })})`}
                  </small>
                </Col>
                <Col xs={1}>
                  {isEdit && (
                    <>
                      {index + 1 === campaign?.prizes?.[0]?.groups?.length && (
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          onClick={handleAddGroup}
                          className="cursor-pointer"
                        />
                      )}
                      {index + 1 !== campaign?.prizes?.[0]?.groups?.length && (
                        <FontAwesomeIcon
                          className="cursor-pointer"
                          onClick={() => handleDeleteGroup(index)}
                          icon={faTrash}
                        />
                      )}
                    </>
                  )}
                </Col>
              </Fragment>
            );
          })}
        </Row>
        <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
          <Col
            xs="auto"
            className="navbar-vertical-label navbar-vertical-label"
          >
            {t('campaign:labels.total')}
          </Col>
          <Col className="ps-0">
            <hr className="mb-0 navbar-vertical-divider"></hr>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Group
              as={Col}
              xs="12"
              className="mb-3"
              controlId="userForm.percentageTotal"
            >
              <Form.Label>{`${t(
                'campaign:labels.percentageTotal'
              )}: `}</Form.Label>
              <Form.Text className="fs-1 mx-2">
                {sumProperty(campaign?.prizes?.[0]?.groups ?? [], 'percentage')}
              </Form.Text>
              {!!campaignErrors?.[`percentageSum`] && (
                <p className="text-danger fs--1">
                  {campaignErrors?.[`percentageSum`]}
                </p>
              )}
            </Form.Group>
          </Col>
          {/* <Col xs={6}>
            <Form.Group
              as={Col}
              xs="12"
              className="mb-3"
              controlId="userForm.percentageValueTotal"
            >
              <Form.Label>
                {`${t('campaign:labels.percentageValueTotal')}: `}
              </Form.Label>
              <Form.Text className="fs-1 mx-2">
                {sumProperty(
                  campaign?.prizes?.[0]?.groups ?? [],
                  'valuePercentage'
                )}
              </Form.Text>

              {!!campaignErrors?.[`valueSum`] && (
                <p className="text-danger fs--1">
                  {campaignErrors?.[`valueSum`]}
                </p>
              )}
            </Form.Group>
          </Col> */}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CampaignPrizes;
