import React, { useState, useEffect, useContext } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { CampaignContext } from 'context/Context';
import MultiLanguageRTE from 'components/common/MultiLanguageRTE';
import ReactDatePicker from 'react-datepicker';
import { parseISOString } from 'helpers/utils';

const CampaignBasicInfoCard = () => {
  const { campaign, setCampaign, campaignErrors, isEdit, loading } =
    useContext(CampaignContext);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const handleChange = (name, value) => {
    if (name === 'titleEn') {
      setCampaign({
        ...campaign,
        title: { ar: campaign?.title?.ar || '', en: value }
      });
      return;
    }
    if (name === 'titleAr') {
      setCampaign({
        ...campaign,
        title: { en: campaign?.title?.en || '', ar: value }
      });
      return;
    }
    if (name === 'descriptionEn') {
      setCampaign({
        ...campaign,
        description: { ar: campaign?.description?.ar || '', en: value }
      });
      return;
    }
    if (name === 'descriptionAr') {
      setCampaign({
        ...campaign,
        description: { en: campaign?.description?.en || '', ar: value }
      });
      return;
    }
    setCampaign({ ...campaign, [name]: value });
  };

  return (
    <Card>
      <Card.Header>{t('organization:labels.basicInfo')}</Card.Header>
      <Card.Body>
        <Row>
          <Col md={6}>
            <Form.Group
              as={Col}
              xs="12"
              className="mb-3"
              controlId="organization.titleEn"
            >
              <Form.Label>{t('organization:form.titleEn')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('organization:form.titleEn')}
                value={campaign?.title?.en || ''}
                onChange={e => handleChange('titleEn', e.target.value)}
                isInvalid={!!campaignErrors?.titleEn}
                disabled={!isEdit}
              />
              <Form.Text className="text-danger">
                {campaignErrors?.titleEn}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group
              as={Col}
              xs="12"
              className="mb-3"
              controlId="organization.titleAr"
            >
              <Form.Label>{t('organization:form.titleAr')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('organization:form.titleAr')}
                value={campaign?.title?.ar || ''}
                onChange={e => handleChange('titleAr', e.target.value)}
                disabled={!isEdit}
                isInvalid={!!campaignErrors?.titleAr}
              />
              <Form.Text className="text-danger">
                {campaignErrors?.titleAr}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group
              as={Col}
              xs="12"
              className="mb-3 d-flex flex-column align-items-start justify-content-center"
              controlId="organization.startDate"
            >
              <Form.Label>{t('organization:form.startDate')}</Form.Label>
              <ReactDatePicker
                selected={
                  campaign?.startDate
                    ? parseISOString(campaign?.startDate)
                    : null
                }
                onChange={date => handleChange('startDate', date.toISOString())}
                wrapperClassName="w-100"
                className="form-control w-100"
                placeholderText="DD-MM-YYYY h:mm aa"
                timeIntervals={5}
                dateFormat="dd-MM-yyyy h:mm aa"
                showTimeSelect
                fixedHeight
                isInvalid={!!campaignErrors?.startDate}
                disabled={!isEdit}
              />

              <Form.Text className="text-danger">
                {campaignErrors?.startDate}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group
              as={Col}
              xs="12"
              className="mb-3 d-flex flex-column align-items-start justify-content-center"
              controlId="organization.endDate"
            >
              <Form.Label>{t('organization:form.endDate')}</Form.Label>

              <ReactDatePicker
                selected={
                  campaign?.endDate ? parseISOString(campaign?.endDate) : null
                }
                onChange={date => handleChange('endDate', date.toISOString())}
                wrapperClassName="w-100"
                className="form-control w-100"
                placeholderText="DD-MM-YYYY h:mm aa"
                timeIntervals={5}
                dateFormat="dd-MM-yyyy h:mm aa"
                showTimeSelect
                fixedHeight
                isInvalid={!!campaignErrors?.endDate}
                disabled={!isEdit}
              />
              <Form.Text className="text-danger">
                {campaignErrors?.endDate}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group
              as={Col}
              xs="12"
              className="mb-3"
              controlId="organization.businessType"
            >
              <Form.Label>{t('organization:form.businessType')}</Form.Label>
              <Form.Select
                as="select"
                placeholder={t('organization:form.businessType')}
                value={campaign?.businessType || ''}
                onChange={e => handleChange('businessType', e.target.value)}
                disabled={!isEdit}
                isInvalid={!!campaignErrors?.businessType}
              >
                <option value="">
                  {t('organization:placeholders.selectBusinessType')}
                </option>
                <option value="ONLINE">
                  {t('organization:businessType.online')}
                </option>
                <option value="OFFLINE">
                  {t('organization:businessType.offline')}
                </option>
              </Form.Select>
              <Form.Text className="text-danger">
                {campaignErrors?.businessType}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Form.Group
            as={Col}
            xs="12"
            className="mb-3"
            controlId="organization.description"
          >
            <Form.Label>{t('organization:form.description')}</Form.Label>
            <MultiLanguageRTE
              t={t}
              enableFullscreen
              activeLanguage={currentLanguage}
              valueEn={campaign?.description?.en || ''}
              valueAr={campaign?.description?.ar || ''}
              errorEn={!!campaignErrors?.descriptionEn}
              errorAr={!!campaignErrors?.descriptionAr}
              errorArMessage={t('common:validation.fieldIsRequired')}
              errorEnMessage={t('common:validation.fieldIsRequired')}
              placeholderEn={t('organization:form.descriptionEn')}
              placeholderAr={t('organization:form.descriptionAr')}
              handleEnChange={newValue => {
                handleChange('descriptionEn', newValue || '');
              }}
              handleArChange={newValue => {
                handleChange('descriptionAr', newValue || '');
              }}
              disabled={!isEdit}
            />
            <Form.Text className="text-danger">
              {campaignErrors?.description}
            </Form.Text>
          </Form.Group>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CampaignBasicInfoCard;
