import http from 'http/interceptor';
import { getItemFromStore } from 'helpers/utils';
const currentLocale =
  getItemFromStore('preferredLanguage', 'en') ||
  process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/${currentLocale}/${process.env.REACT_APP_API_VERSION}`;
const CREATE_USER_URL = `${BASE_API_URL}/user/create`;
const UPDATE_USER_PROFILE_URL = `${BASE_API_URL}/user/edit`;
const LIST_USER_RL = `${BASE_API_URL}/user/list`;
const LIST_ROLES_RL = `${BASE_API_URL}/user/roles/list`;
const CHANGE_STATUS_URL = `${BASE_API_URL}/user/changeuserstatus`;
const CHANGE_PASSWORD_URL = `${BASE_API_URL}/user/changePassword`;
const UPDATE_USER_ROLES_URL = `${BASE_API_URL}/user/changeuserroles`;
const USER_REQUEST_PASSWORD_CHANGE_URL = `${BASE_API_URL}/user/changePassword`;
const USER_UPLOAD_PROFILE_IMAGE_URL = `${BASE_API_URL}/user/uploadavatar`;
const CURRENT_USER_PROFILE_INFO_URL = `${BASE_API_URL}/user/currentuser`;
const USER_PROFILE_INFO_URL = `${BASE_API_URL}/user/getuserprofile`;
const USER_ORGANIZATION_URL = `${BASE_API_URL}/user/organization/get`;

const UserService = {
  create: data => {
    return http.post(
      CREATE_USER_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  update: data => {
    return http.post(
      UPDATE_USER_PROFILE_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  list: payload => {
    const url = `${LIST_USER_RL}`;

    return http.post(url, payload, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  listRoles: () => {
    const url = `${LIST_ROLES_RL}`;
    return http.get(url, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  changeStatus: ({ userId, newStatus }) => {
    const url = `${CHANGE_STATUS_URL}`;
    return http.post(
      url,
      { userId, newStatus },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  changePassword: ({ UserId, NewPassword }) => {
    const url = `${CHANGE_PASSWORD_URL}`;
    return http.post(
      url,
      { UserId, NewPassword },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  updateRoles: ({ UserId, NewRoles }) => {
    const url = `${UPDATE_USER_ROLES_URL}`;
    return http.post(
      url,
      { UserId, NewRoles },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  requestPasswordChange: ({
    userId,
    oldPassword,
    newPassword,
    confirmPassword
  }) => {
    const url = `${USER_REQUEST_PASSWORD_CHANGE_URL}`;
    return http.post(
      url,
      { userId, oldPassword, newPassword, confirmPassword },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  uploadProfileImage: ({ userId, file }) => {
    const url = `${USER_UPLOAD_PROFILE_IMAGE_URL}`;

    const formData = new FormData();
    formData.append('file', file);
    return http.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  },
  getProfileInfo: () => {
    const url = `${CURRENT_USER_PROFILE_INFO_URL}`;
    return http.get(url, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  },
  getUserInfo: id => {
    const url = `${USER_PROFILE_INFO_URL}?userId=${id}`;
    return http.get(url, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  },
  getUserOrganization: (id, headquarter) => {
    const url = `${USER_ORGANIZATION_URL}?userId=${id}&headquarter=${headquarter}`;

    return http.get(url);
  }
};

export default UserService;
