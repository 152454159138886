import { createContext } from 'react';
import { settings } from 'config';

const AppContext = createContext(settings);

export const DataContext = createContext({ countries: [] });

export const AuthContext = createContext({ user: null, isLoggedIn: false });

export const CampaignContext = createContext({ campaign: null });

export default AppContext;
