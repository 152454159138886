import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from 'context/Context';
import AuthService from 'http/AuthService';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import { useTranslation } from 'react-i18next';
import jwt_decode from 'jwt-decode';
import { getRedirectUrl } from 'helpers/utils';
import { flattenRoutes } from 'routes/routes';

const isAuthorizedPath = (url, roles) => {
  const route = flattenRoutes.find(c => c.to.startsWith(url));
  if (!route) {
    return false;
  }
  if (
    typeof roles === 'string' &&
    (!route.roles || route.roles.includes(roles))
  ) {
    return true;
  }
  if (
    Array.isArray(roles) &&
    (!route.roles || route.roles.some(r => roles.includes(r)))
  ) {
    return true;
  }

  return false;
};

const isUserLoggedIn = () => {
  let userAuthData = null;
  try {
    userAuthData = JSON.parse(localStorage.getItem('SPINNER_admin_user'));
  } catch {
    userAuthData = null;
  }
  if (!!userAuthData?.token) {
    //console.log('logged in ...');
    return true;
  }
  return false;
};

const getLoggedInUser = () => {
  let userAuthData = null;
  try {
    userAuthData = JSON.parse(localStorage.getItem('SPINNER_admin_user'));
  } catch {
    userAuthData = null;
  }
  return userAuthData;
};

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    user: getLoggedInUser(),
    isLoggedIn: isUserLoggedIn()
  });
  const [returnUrl, setReturnUrl] = useState(null);
  const { t } = useTranslation();

  const login = ({ ...userData }, location, onFail) => {
    AuthService.login({
      username: userData.email,
      password: userData.password
    })
      .then(response => {
        if (response.userName === (userData.email || userData.emailAddress)) {
          var decoded = jwt_decode(response.token);
          //console.log('decoded', decoded);
          toast.success(t('auth:loggedInAs', { username: userData.email }), {
            theme: 'colored'
          });
          const baseUrl = getRedirectUrl({
            ...response,
            roles: decoded.role,
            remember: userData.remember
          });
          if (location?.state?.from?.pathname) {
            if (
              isAuthorizedPath(location?.state?.from?.pathname, decoded.role)
            ) {
              window.location.href = location?.state?.from?.pathname;
            } else {
              window.location.href = baseUrl;
            }
          } else {
            window.location.href = baseUrl;
          }
          localStorage.setItem(
            'SPINNER_admin_user',
            JSON.stringify({
              ...response,
              roles: decoded.role,
              remember: userData.remember
            })
          );
        } else {
          onFail && onFail();
          console.log('login incorrect data', response);
          toast.error(getErrorMessage(t, response), {
            theme: 'colored',
            autoClose: false
          });
        }
      })
      .catch(error => {
        console.log('login error', error);
        onFail && onFail();
        toast.error(getErrorMessage(t, error), {
          theme: 'colored',
          autoClose: false
        });
      });
  };

  const logout = () => {
    localStorage.removeItem('SPINNER_admin_user');
    setAuthState(null);
    if (returnUrl) {
      // window.location.href = '/';
    }
  };

  const updateUser = userData => {
    setAuthState({ ...authState, user: { ...userData } });
    localStorage.setItem('SPINNER_admin_user', JSON.stringify({ ...userData }));
  };

  const isAdmin =
    authState?.user?.roles === 'admin' ||
    authState?.user?.roles?.includes('admin');
  const isManager =
    authState?.user?.roles === 'manager' ||
    authState?.user?.roles?.includes('manager');

  const authProviderValue = {
    user: authState?.user || null,
    setUser: updateUser,
    isLoggedIn: authState?.isLoggedIn || false,
    returnUrl: null,
    getRedirectUrl,
    setReturnUrl,
    login,
    logout,
    isAdmin,
    isManager
  };

  return (
    <AuthContext.Provider value={authProviderValue}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = { children: PropTypes.node };
export default AuthProvider;
