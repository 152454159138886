import React, { ReactDOM } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { createPortal } from 'react-dom';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';

const LoadingIcon = ({ id }) => {
  return (
    <div id={id} className="global-spinner-placeholder">
      <Spinner variant="primary" />
    </div>
  );
};

const LoadingSpinner = ({ id, loading }) => {
  const spinnerId = `global-loading-spinner-${id}`;

  const loadingOverlay = document.createElement('div');
  loadingOverlay.id = spinnerId;
  loadingOverlay.className = 'global-spinner-placeholder';

  useEffect(() => {
    if (loading) {
      // Append the loading overlay to the body element

      // Create a React portal to render the cloned LoadingSpinner component inside the loading overlay
      //const portal = createPortal(<LoadingIcon />, loadingOverlay);

      const iconHtml = ReactDOMServer.renderToString(
        <LoadingIcon id={spinnerId} />
      );

      // Set the innerHTML of the loading overlay with the HTML representation of the component
      loadingOverlay.innerHTML = iconHtml;

      document.body.appendChild(loadingOverlay);

      // Apply styling to block the entire screen
      document.body.style.overflow = 'hidden'; // Prevent scrolling
    } else {
      document.body.style.overflow = 'auto'; // Restore scrolling

      const loader = document.getElementById(spinnerId);
      if (!!loader) {
        document.body.removeChild(loader);
      }
    }
  }, [loading]);

  return null;
};

export default LoadingSpinner;
