import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import LoaderWithMessage from 'components/common/LoaderWithMessage';
import ErrorPlaceholder from 'components/common/ErrorPlaceholder';
import {
  Card,
  Col,
  Dropdown,
  Offcanvas,
  Placeholder,
  Row
} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { useBreakpoints } from 'hooks/useBreakpoints';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { Link } from 'react-router-dom';
import SoftBadge from 'components/common/SoftBadge';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faToggleOff,
  faToggleOn,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import ConfirmModal from 'components/common/ConfirmModal';
import { getErrorMessage, toFileUrl } from 'http/utils';
import OrganizationsListHeader from './OrganizationsListHeader';
import OrganizationsFilters from './OrganizationsFilters';
import OrganizationService from 'http/OrganizationService';

const defaultPaging = {
  pageNumber: 1,
  pageSize: 10
};

const defaultSorting = {
  sortBy: 'metadata.createdAt',
  sortDirection: 'DESC'
};

const OrganizationsList = () => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const currentLanguage = i18n.language;
  const [organizations, setOrganizations] = useState([]);
  const [pagingInfo, setPagingInfo] = useState(null);
  const [sortInfo, setSortInfo] = useState({ ...defaultSorting });
  const [filters, setFilters] = useState({
    ...defaultPaging,
    ...defaultSorting
  });
  const [show, setShow] = useState(false);
  const { breakpoints } = useBreakpoints();
  const [confirmModalProps, setConfirmModalProps] = useState(null);

  const [requestLoading, setRequestLoading] = useState(false);
  const [organizationToEdit, setOrganizationToEdit] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    isLoading: organizationsLoading,
    error: organizationsError,
    data: organizationsData,
    refetch: fetchOrganizations
  } = useQuery(
    ['OrganizationsList', { ...filters, ...sortInfo }],
    () => OrganizationService.list({ ...filters, ...sortInfo }),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    fetchOrganizations({ ...filters, ...sortInfo, ...defaultPaging });
  }, [filters, sortInfo]);

  useEffect(() => {
    if (organizationsData && !organizationsLoading && !organizationsError) {
      setOrganizations(organizationsData.data);
      setPagingInfo(organizationsData.pagingInfo);
    }
    if (organizationsError) {
      toast.error(getErrorMessage(t, organizationsError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [organizationsLoading, organizationsError, organizationsData]);

  const handleSort = column => {
    //console.log('handleSort', column);
    const direction = sortInfo.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    setSortInfo({
      sortBy: column,
      sortDirection: direction
    });
  };

  const handleDeleteClick = id => {
    setConfirmModalProps({
      isOpen: true,
      onClose: () => setConfirmModalProps(null),
      onConfirm: () => handleDelete(id),
      message: t('organization:message.deleteConfirm'),
      header: t('organization:message.deleteConfirmHeader'),
      actionButtonColor: 'danger',
      actionButtonText: t('common:button.delete')
    });
  };

  const handleActivateClick = id => {
    setConfirmModalProps({
      isOpen: true,
      onClose: () => setConfirmModalProps(null),
      onConfirm: () => handleUpdateStatus({ id, status: 'active' }),
      message: t('organization:message.activateConfirm'),
      header: t('organization:message.activateConfirmHeader'),
      actionButtonColor: 'success',
      actionButtonText: t('common:button.activate')
    });
  };

  const handleSuspendClick = id => {
    setConfirmModalProps({
      isOpen: true,
      onClose: () => setConfirmModalProps(null),
      onConfirm: () => handleUpdateStatus({ id, status: 'suspended' }),
      message: t('organization:message.suspendConfirm'),
      header: t('organization:message.suspendConfirmHeader'),
      actionButtonColor: 'warning',
      actionButtonText: t('common:button.suspend')
    });
  };

  const handleDelete = id => {
    setConfirmModalProps({
      ...confirmModalProps,
      loading: true
    });

    OrganizationService.delete(id)
      .then(response => {
        fetchOrganizations({ ...filters, ...sortInfo, ...defaultPaging });
        toast.success(t('organization:message.deleteSuccess'));
      })
      .catch(error => {
        toast.error(getErrorMessage(error));
      });
  };

  const handleUpdateStatus = data => {
    setConfirmModalProps({
      ...confirmModalProps,
      loading: true
    });

    OrganizationService.updateStatus({ ...data })
      .then(response => {
        fetchOrganizations({ ...filters, ...sortInfo, ...defaultPaging });
        toast.success(t('organization:message.updateSuccess'));
      })
      .catch(error => {
        toast.error(getErrorMessage(error));
      });
  };

  const columns = [
    {
      accessor: 'name',
      Header: t('organization:table.name'),
      headerProps: {
        className: 'px-3 text-center',
        style: { height: '46px' },
        onClick: () => handleSort('NormalizedDisplayName')
      },
      cellProps: {
        className: 'py-2 white-space-nowrap px-3 pe-xxl-4 '
      },
      Cell: rowData => {
        const { name, avatarUrl, id } = rowData.row.original;
        return (
          <Flex alignItems="center" className="position-relative py-1 ">
            <Avatar
              src={toFileUrl(avatarUrl)}
              size="xl"
              className="me-2"
              name={name || t('common:labels.unknown')}
            />
            <Link
              to={`/organization/settings/${id}`}
              className="stretched-link fw-semi-bold"
            >
              {name}
            </Link>
          </Flex>
        );
      }
    },
    {
      accessor: 'type',
      Header: t('organization:table.type'),
      headerProps: {
        className: 'px-3 text-center',
        style: { height: '46px' }
      },
      cellProps: {
        className: 'py-2 white-space-nowrap px-3 pe-xxl-4 '
      },
      Cell: rowData => {
        const { parentId } = rowData.row.original;
        return (
          <SoftBadge
            bg={!parentId ? 'primary' : 'warning'}
            className="me-2 mt-2"
          >
            {!!parentId
              ? t(`organization:type.branch`)
              : t(`organization:type.headQuarter`)}
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'country',
      Header: t('organization:table.country'),
      headerProps: {
        onClick: () => handleSort('NormalizedEmail')
      },
      cellProps: {
        className: 'py-2 pe-4'
      },
      Cell: rowData => {
        const { country } = rowData.row.original;
        return (
          <p className="fw-semi-bold break-word m-0 mt-2">{`${country.name?.[currentLanguage]}`}</p>
        );
      }
    },
    {
      accessor: 'city',
      Header: t('organization:table.city'),
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'pe-4'
      },
      disableSortBy: true,
      Cell: rowData => {
        const { city } = rowData.row.original;
        return <p className="fw-semi-bold break-word m-0 mt-2">{`${city}`}</p>;
      }
    },
    {
      accessor: 'contact',
      Header: t('organization:table.contact'),
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'pe-4'
      },
      disableSortBy: true,
      Cell: rowData => {
        const { contactEmail, contactPhone, additionalContactPhone } =
          rowData.row.original;
        return (
          <div className="d-flex align-items-start justify-content-center flex-column">
            <p className="fw-semi-bold break-word m-0 mt-2">
              {`${t('organization:table.contactEmail')} : `}
              <a href="mailto:contactEmail">{contactEmail || ''}</a>
            </p>
            <p className="fw-semi-bold break-word m-0 mt-2">{`${t(
              'organization:table.contactPhone'
            )} : ${contactPhone || ''}`}</p>
            <p className="fw-semi-bold break-word m-0 mt-2">{`${t(
              'organization:table.additionalContactPhone'
            )} : ${additionalContactPhone || ''}`}</p>
          </div>
        );
      }
    },
    {
      accessor: 'status',
      Header: t('organization:table.status'),
      headerProps: {
        onClick: () => handleSort('Status')
      },
      cellProps: {
        className: 'fs-0 '
      },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return (
          <SoftBadge
            bg={
              status === 'active'
                ? 'success'
                : status === 'suspended'
                ? 'warning'
                : 'dark'
            }
            className="me-2 mt-2"
          >
            {status ? t(`common:status.${status}`) : t(`common:labels.unknown`)}
          </SoftBadge>
        );
      }
    },

    {
      accessor: 'metadata.createdAt',
      Header: t('organization:table.createdAt'),
      headerProps: {
        className: 'text-start',
        onClick: () => handleSort('metadata.createdAt')
      },
      Cell: rowData => {
        const { metadata } = rowData.row.original;
        return (
          <p className="fw-semi-bold mb-0 mt-2">
            {metadata?.createdAt
              ? moment(metadata?.createdAt || new Date())
                  .locale(currentLanguage)
                  .format('YYYY-MM-DD hh:mm A')
              : t('common:labels.noDataAvailable')}
          </p>
        );
      }
    },
    {
      accessor: 'action',
      Header: t('organization:table.action'),
      headerProps: { className: 'text-start' },
      disableSortBy: true,
      exportable: false,
      Cell: rowData => {
        const { status, id } = rowData.row.original;
        const isActive = status === 'active';

        return (
          <Dropdown
            align="end"
            className="btn-reveal-trigger d-inline-block mt-2"
          >
            <Dropdown.Toggle split variant="spinner-default" size="sm">
              <FontAwesomeIcon icon="ellipsis-h" className="fs--2" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="border py-0">
              <div className="py-2">
                <Dropdown.Item onClick={() => handleDeleteClick(id)}>
                  <FontAwesomeIcon icon={faTrash} className="mx-1" />
                  {t('common:button.delete')}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setOrganizationToEdit(rowData.row.original)}
                >
                  <FontAwesomeIcon icon={faEdit} className="mx-1" />
                  {t('common:button.edit')}
                </Dropdown.Item>

                {isActive && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      className="text-danger"
                      onClick={() => handleSuspendClick(id)}
                    >
                      <FontAwesomeIcon icon={faToggleOff} className="mx-1" />
                      {t('common:button.suspend')}
                    </Dropdown.Item>
                  </>
                )}
                {!isActive && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      className="text-success"
                      onClick={() => handleActivateClick(id)}
                    >
                      <FontAwesomeIcon icon={faToggleOn} className="mx-1" />
                      {t('common:button.activate')}
                    </Dropdown.Item>
                  </>
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        );
      }
    }
  ];

  const handleFilterChange = newFilters => {
    setFilters({
      ...newFilters
    });
  };

  if (organizationsError) {
    return (
      <ErrorPlaceholder
        message={t('common:loading.errorOrganizations')}
        className="mt-3"
      />
    );
  }

  if (organizationsLoading) {
    <LoaderWithMessage
      message={t('common:loading.organizations')}
      className="mt-3"
    />;
  }

  return (
    <Row className="gx-3">
      <Col xxl={10} xl={9}>
        <Card>
          <Card.Header className="border-bottom border-200 px-0">
            <OrganizationsListHeader
              table
              layout="table-view"
              handleShow={handleShow}
              handleSearch={searchTerm =>
                handleFilterChange({ search: searchTerm })
              }
              t={t}
              refetch={fetchOrganizations}
              organization={organizationToEdit}
              setOrganizationToEdit={setOrganizationToEdit}
              filters={filters}
              setFilters={setFilters}
              sortInfo={sortInfo}
              tableColumns={columns}
              totalCount={pagingInfo?.totalItems}
            />
          </Card.Header>
          <Card.Body className={organizationsLoading ? '' : 'p-0'}>
            {organizationsLoading &&
              Array(14)
                .fill(1)
                .map((n, i) => {
                  return (
                    <Placeholder
                      key={`organization-table-skeleton-${i}`}
                      className="w-100 my-1"
                      style={{ height: '33px' }}
                      size="lg"
                    />
                  );
                })}
            {!organizationsLoading && (
              <AdvanceTableWrapper
                columns={columns}
                data={organizations || []}
                sortable
                pagination
                perPage={10}
                rowCount={organizations?.length || 0}
                manualSortBy
              >
                <AdvanceTable
                  table
                  headerClassName="bg-light text-800 align-middle"
                  rowClassName="btn-reveal-trigger align-top"
                  tableProps={{
                    bordered: true,
                    striped: true,
                    hover: true
                  }}
                />
              </AdvanceTableWrapper>
            )}
            {!organizationsLoading && !organizations?.length && (
              <p className="w-100 text-center">
                {t('common:placeholder.noDataAvailable')}
              </p>
            )}
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination
              table
              pageCount={pagingInfo?.totalPages || 0}
              pageIndex={pagingInfo?.currentPage || 1}
              gotoPage={page => {
                setFilters({ ...filters, pageNumber: page });
              }}
              isRtl={isRtl}
            />
          </Card.Footer>
        </Card>
      </Col>
      <Col xxl={2} xl={3}>
        {breakpoints.down('xl') ? (
          <Offcanvas
            show={show}
            onHide={handleClose}
            placement="end"
            className="dark__bg-card-dark"
          >
            <Offcanvas.Header closeButton className="bg-light">
              <h6 className="fs-0 mb-0 fw-semi-bold">
                {t('common:labels.filters')}
              </h6>
            </Offcanvas.Header>
            <OrganizationsFilters
              setShow={setShow}
              oldFilters={filters}
              onSubmit={newFilters =>
                handleFilterChange({
                  ...newFilters
                })
              }
            />
          </Offcanvas>
        ) : (
          <OrganizationsFilters
            setShow={setShow}
            oldFilters={filters}
            onSubmit={newFilters =>
              handleFilterChange({
                ...newFilters
              })
            }
          />
        )}
      </Col>
      {!!confirmModalProps && (
        <ConfirmModal {...confirmModalProps} loading={requestLoading} />
      )}
    </Row>
  );
};

export default OrganizationsList;
