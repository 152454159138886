import LoaderWithMessage from 'components/common/LoaderWithMessage';
import SelectComponent from 'components/common/SelectComponent';
import { AuthContext, CampaignContext } from 'context/Context';
import CampaignService from 'http/CampaignService';
import { getErrorMessage } from 'http/utils';
import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Card, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import PricingCard from './PricingCard';
import { useNavigate } from 'react-router-dom';

const PackageModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { t, i18n, isEdit } = useTranslation();
  const { user } = useContext(AuthContext);
  const currentLanguage = i18n.language;
  const [packagesOptions, setPackagesOptions] = useState([]);

  const {
    isLoading: packagesLoading,
    error: packagesError,
    data: packagesData,
    refetch: fetchPackages
  } = useQuery(['ListPackages'], () => CampaignService.listPackages(), {
    enabled: false, // Prevents automatic refetching
    retry: false,
    manual: true,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (isOpen) {
      fetchPackages();
    }
  }, [isOpen]);

  useEffect(() => {
    if (packagesData && !packagesLoading && !packagesError) {
      console.log('packagesData', { packagesData });
      setPackagesOptions(packagesData);
    }
    if (packagesError) {
      toast.error(getErrorMessage(t, packagesError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [packagesData, packagesError, packagesLoading]);

  // if (packagesLoading) {
  //   return (
  //     <LoaderWithMessage message={t('campaign:loading.loadingPackages')} />
  //   );
  // }

  return (
    <Modal size="xl" show={isOpen} onHide={onClose} centered>
      <Modal.Header onClose={onClose}>
        {t('campaign:labels.packageTitle')}
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Row>
          {packagesOptions?.map((pack, i) => {
            const pricing = {
              id: pack.id,
              title: pack.title?.[currentLanguage],
              subTitle: `${pack.subtitle?.[currentLanguage]} (${
                pack.tokensCount
              }) ${t('campaign:labels.tokens')}`,
              description: pack.description?.[currentLanguage],
              price: pack.perTokenPrice,
              tokens: pack.tokensCount,
              totalPrice: pack.price,
              currencyCode: user?.currency?.code?.[currentLanguage],
              buttonText: t('common:button.select'),
              isFeatured: i === 0,
              featureTitle: pack.subtitle?.[currentLanguage],
              features: pack.features ?? [],
              pricePer: t('campaign:labels.perToken'),
              url: `/campaign?packageId=${pack.id}`
            };
            return <PricingCard {...pricing} key={pack.id} />;
          })}
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default PackageModal;
