import AppContext from 'context/Context';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import Select from 'react-select';

const SelectComponent = ({ invalid, errorText, isDisabled, ...rest }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [styles, setStyles] = useState({});

  useEffect(() => {
    if (invalid) {
      setStyles({
        control: (base, state) => ({
          ...base,
          border: '1px solid #C1272D !important',
          borderColor: '#C1272D',
          boxShadow: state.isFocused ? '#C1272D' : '',
          '&:hover': {
            borderColor: '#C1272D'
          },
          zIndex: 5,
          background: isDisabled ? '#edf2f9!important' : ''
        }),
        menu: base => ({
          ...base,
          zIndex: 5
        }),
        listBox: base => ({
          ...base,
          zIndex: 5
        })
      });
    } else {
      setStyles({
        control: (base, state) => ({
          ...base,
          border: state.isFocused ? '1px solid #737aff !important' : '',
          borderColor: state.isFocused ? '#737aff' : 'lightGrey',
          boxShadow: state.isFocused ? '#737aff' : 'lightGrey',
          '&:hover': {
            borderColor: state.isFocused ? '#737aff' : ''
          },
          zIndex: 4,
          background: isDisabled
            ? isDark
              ? '#232e3c!important'
              : '#edf2f9!important'
            : ''
        }),
        menu: base => ({
          ...base,
          zIndex: 5
        }),
        listBox: base => ({
          ...base,
          zIndex: 5
        })
      });
    }
  }, [invalid, isDisabled]);

  return (
    <>
      <Select {...rest} isDisabled={isDisabled} styles={styles} />
      {errorText && <small className="text-danger">{errorText}</small>}
    </>
  );
};

export default SelectComponent;
