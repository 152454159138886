import React from 'react';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import Dashboard from 'pages/dashboard';
import UserList from 'pages/User/UserList';
import LoginPage from 'pages/Auth/LoginPage';
import Settings from 'pages/User/settings/UserProfileSettings';
import OrganizationSettings from 'pages/Organizations/OrganizationSettings';
import Profile from 'pages/User/profile/Profile';
import Error401 from 'components/errors/Error401';
import { faNewspaper } from '@fortawesome/free-regular-svg-icons';
import {
  faBookOpen,
  faBullhorn,
  faCog,
  faFile,
  faGraduationCap,
  faGripLinesVertical,
  faMapSigns,
  faMoneyBill,
  faSitemap,
  faTag,
  faUsers,
  faVial
} from '@fortawesome/free-solid-svg-icons';
import OrganizationsList from 'pages/Organizations/OrganizationsList';
import CampaignProvider from 'providers/CampaignProvider';
import CreateCampaign from 'pages/campaign/CreateCampaign';
import CampaignsList from 'pages/campaign/CampaignsList';

const CreateCampaignPage = () => {
  return (
    <CampaignProvider>
      <CreateCampaign />
    </CampaignProvider>
  );
};

export const dashboardRoutes = {
  label: 'dashboard',
  //labelDisable: true,
  menu: true,
  layout: 'main',
  children: [
    {
      name: 'dashboard',
      active: true,
      icon: 'chart-pie',
      to: '/dashboard',
      path: '/dashboard',
      component: () => <Dashboard />,
      exact: true,
      layout: 'main'
    }
  ]
};

export const adminRoutes = {
  label: 'admin',
  menu: true,
  layout: 'main',
  children: [
    {
      name: 'organizations',
      active: true,
      icon: faSitemap,
      to: '/admin/organizations',
      path: '/admin/organizations',
      component: () => <OrganizationsList />,
      exact: true,
      roles: ['admin'],
      layout: 'main'
    },
    {
      name: 'users',
      active: true,
      icon: faUsers,
      to: '/admin/users',
      path: '/admin/users',
      component: () => <UserList />,
      exact: true,
      roles: ['admin', 'manager'],
      layout: 'main'
    },
    {
      name: 'createCampaign',
      active: true,
      icon: faUsers,
      to: '/campaign/:id?',
      path: '/campaign/:id?',
      component: () => <CreateCampaignPage />,
      exact: true,
      roles: ['admin', 'manager'],
      layout: 'main',
      hidden: true
    },
    {
      name: 'campaignsList',
      active: true,
      icon: faBullhorn,
      to: '/campaigns',
      path: '/campaigns',
      component: () => <CampaignsList />,
      exact: true,
      roles: ['admin', 'manager'],
      layout: 'main'
    }
  ],
  roles: ['admin', 'manager']
};

export const userRoutes = {
  label: 'user',
  menu: true,
  layout: 'main',
  hidden: true,
  children: [
    {
      name: 'userProfile',
      active: true,
      icon: faUsers,
      to: '/user/profile/:id?',
      path: '/user/profile/:id?',
      component: () => <Profile />,
      exact: true,
      hidden: true,
      layout: 'main'
    },
    {
      name: 'userSettings',
      active: true,
      icon: faCog,
      to: '/user/settings/:id?',
      path: '/user/settings/:id?',
      component: () => <Settings />,
      exact: true,
      hidden: true,
      layout: 'main'
    }
  ]
};

export const authRoutes = {
  label: '',
  menu: false,
  layout: 'auth',
  children: [
    {
      name: 'login',
      active: true,
      icon: 'sign-in',
      to: '/login',
      path: '/login',
      component: () => <LoginPage />,
      exact: true,
      hidden: true,
      layout: 'auth'
    }
  ],
  hidden: true
};

export const errorRoutes = {
  label: '',
  menu: false,
  layout: 'error',
  hidden: true,
  children: [
    {
      name: '404',
      active: false,
      icon: '',
      to: '/not-found',
      path: '/not-found',
      component: () => <Error404 />,
      exact: true,
      hidden: true,
      layout: 'error'
    },
    {
      name: '401',
      active: false,
      icon: '',
      to: '/unauthorized',
      path: '/unauthorized',
      component: () => <Error401 />,
      exact: true,
      hidden: true,
      layout: 'error'
    },
    {
      name: '500',
      active: false,
      icon: '',
      to: '/error',
      path: '/error',
      component: () => <Error500 />,
      exact: true,
      hidden: true,
      layout: 'error'
    }
  ]
};

export const settingsRoutes = {
  label: 'settings',
  menu: true,
  layout: 'main',
  children: [
    {
      name: 'organizationSettings',
      active: true,
      icon: faCog,
      to: '/organization/settings/:id?',
      path: '/organization/settings/:id?',
      component: () => <OrganizationSettings />,
      exact: true,
      roles: ['admin', 'manager'],
      layout: 'main'
    }
  ],
  roles: ['admin', 'manager']
};

export default [
  dashboardRoutes,
  adminRoutes,
  userRoutes,
  authRoutes,
  settingsRoutes
];

export const flattenRoutes = [
  dashboardRoutes,
  userRoutes,
  adminRoutes,
  errorRoutes,
  authRoutes,
  settingsRoutes
]
  ?.map(r => r.children || [])
  .flat(1);
