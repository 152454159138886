import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { AuthContext, DataContext } from 'context/Context';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import CoreService from 'http/CoreService';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';

const DataProvider = ({ children }) => {
  const { isLoggedIn } = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [cities, setCities] = useState([]);

  const countriesOptions = countries.map(c => ({
    label: c.name?.[currentLang],
    value: c?.name?.en.toLowerCase().replaceAll(' ', '').trim(),
    code: c.code,
    isoCode: c.isoCode,
    ...c
  }));

  const citiesOptions = cities?.map(c => ({
    label: c.name?.[currentLang],
    value: c?.name?.en.toLowerCase().replaceAll(' ', '').trim(),
    ...c
  }));

  const {
    isLoading: countriesLoading,
    error: countriesError,
    data: countriesData,
    refetch: fetchCountries
  } = useQuery(
    ['CountriesList', { pageNumber: 1, pageSize: 300 }],
    () => CoreService.listCountries({ pageNumber: 1, pageSize: 300 }),
    {
      enabled: false,
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false
    }
  );

  const {
    isLoading: citiesLoading,
    error: citiesError,
    data: citiesData,
    refetch: fetchCities
  } = useQuery(
    [
      'CitiesList',
      {
        pageNumber: 1,
        pageSize: 300,
        countryCode: selectedCountry?.code?.toUpperCase()
      }
    ],
    () =>
      CoreService.listCities({
        pageNumber: 1,
        pageSize: 300,
        countryCode: selectedCountry?.code?.toUpperCase()
      }),
    {
      enabled: false,
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    if (isLoggedIn) {
      fetchCountries();
    }
  }, []);

  useEffect(() => {
    if (!!selectedCountry?.code) {
      fetchCities({ countryCode: selectedCountry?.code?.toUpperCase() });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (countriesData && !countriesError && !countriesLoading) {
      setCountries(countriesData?.payload?.data);
    }
    if (countriesError) {
      toast.error(getErrorMessage(countriesError));
    }
  }, [countriesData, countriesError, countriesLoading]);

  useEffect(() => {
    if (citiesData && !citiesError && !citiesLoading) {
      setCities(citiesData?.payload?.data);
    }
    if (citiesError) {
      toast.error(getErrorMessage(citiesError));
    }
  }, [citiesData, citiesError, citiesLoading]);

  const dataProviderValue = {
    countries: countriesOptions,
    cities: citiesOptions,
    setSelectedCountry,
    selectedCountry,
    setCities
  };

  return (
    <DataContext.Provider value={dataProviderValue}>
      {children}
    </DataContext.Provider>
  );
};

DataProvider.propTypes = { children: PropTypes.node };
export default DataProvider;
